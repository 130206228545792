import * as React from 'react';
import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function HardwareSetTable({ hardwaresets }) {

    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table aria-label="Hardware set">
                <TableHead>
                    <TableRow>
                        <TableCell>Hardware set</TableCell>
                        <TableCell>state</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hardwaresets.map((set) => (
                    <TableRow key={set.name}>
                        <TableCell>
                        <Link href="#" onClick={() => navigate(`/devices/hardwaresets/${set.id}`)}>{set.name}</Link>
                        </TableCell>
                        <TableCell>
                            {set.state}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}