import React from "react";

import DevicesImportForm from "../organisms/devices/DevicesImportForm";
import Page from "./Page";

export default function DevicesImport() {

    return (
        <Page title="Import Devices">
            <DevicesImportForm />
        </Page>
    );
}
