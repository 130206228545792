import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import { LoadingState } from "../atoms/LoadingState";
import AlarmFlowsTable from "../organisms/alarmflows/AlarmFlowsTable";
import Page from "./Page";
import LoadingMessages from "../molecules/LoadingMessages";

export default function AlarmFlowsOverview() {
  const auth = useAuth();
  const organization = useSelector((state) => state.organization);

  const [alarmFlows, setAlarmFlows] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
  const [deletedAlarmFlowId, setDeletedAlarmFlowId] = useState(null);

  async function getAlarmFlows() {
    const token = auth.user?.access_token;
    getItems({
      token: token,
      path: "/v3alpha/alarm-flows?organizationId=" + organization.id,
      setLoadingState: setLoadingState,
      setItems: (flows) => {
        setAlarmFlows(
          flows.sort((s1, s2) =>
            s1.name.toLowerCase().localeCompare(s2.name.toLowerCase())
          )
        );
      },
    });
  }

  const getAlarmFlowsCallBack = useCallback(getAlarmFlows, [
    auth,
    organization,
  ]);

  const loadAlarmFlows = () => {
    if (organization.id) {
      setLoadingState(LoadingState.LOADING);
      getAlarmFlowsCallBack();
    } else {
      setLoadingState(LoadingState.IDLE);
    }
  };

  useEffect(loadAlarmFlows, [
    getAlarmFlowsCallBack,
    organization,
    deletedAlarmFlowId,
  ]);

  return (
    <Page
      title={
        "Alarm Flows" +
        (organization.id ? " of " + organization.description : "")
      }
    >
      <LoadingMessages
        loadingState={loadingState}
        entries={alarmFlows}
        messages={{
          idle: "Please select an organization.",
          noEntriesFound:
            "Currently, no alarm flows are configured for this organization.",
          error: "The alarms flows for this organization could not be loaded.",
        }}
      />
      {loadingState === LoadingState.LOADED && alarmFlows.length > 0 && (
        <AlarmFlowsTable
          alarmFlows={alarmFlows}
          setDeletedAlarmFlowId={setDeletedAlarmFlowId}
        />
      )}
      {loadingState === LoadingState.LOADED && organization.id && (
        <Tooltip title="New">
          <IconButton
            id="new"
            name="New"
            component={RouterLink}
            to="/alarms/flows/form"
            variant="contained"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </Page>
  );
}
