import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link as RouterLink } from "react-router-dom";
import ConfirmationDialog from "../../../molecules/ConfirmationDialog";

export default function ShiftForm({ initShift, createShift, updateShift, deleteShift }) {

    const id = initShift ? initShift.id : null;
    const organization = useSelector(state => state.organization);

    const [name, setName] = useState(initShift ? initShift.name : "");
    const [description, setDescription] = useState(initShift ? initShift.description : "");

    const create = "create";
    const edit = "edit";
    const formMode = initShift && initShift.id ? edit : create;

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    return (
        <Stack spacing={2}>
            <TextField id="name" label="Name" variant="outlined"
                value={name}
                required="true"
                inputProps={{ maxLength: 64 }}
                floatingLabelText="Name"
                onChange={event => setName(event.target.value)} />
            <TextField id="description" label="Description" variant="outlined"
                value={description}
                required="true"
                floatingLabelText="Description"
                inputProps={{ maxLength: 256 }}
                onChange={event => setDescription(event.target.value)} />
            {
                formMode === create ?
                    <Stack spacing={2} direction="row">
                        <Button id="cancel" name="Cancel" component={RouterLink}
                            to="/alarms/shifts/overview" variant="contained">Cancel</Button>
                        <Button id="create" name="Create" onClick={() => createShift({ name: name, description: description, organizationId: organization.id })}
                            disabled={!name || !description || !organization.id} variant="contained">Create</Button>
                    </Stack>
                    :
                    <Stack spacing={2} direction="row">
                        <Button id="cancel" name="Cancel" component={RouterLink}
                            to="/alarms/shifts/overview" variant="contained">Cancel</Button>
                        <Button id="delete" name="Delete" onClick={() => setDeleteDialogOpen(true)} variant="contained">Delete</Button>
                        <Button id="update" name="Update" onClick={() => updateShift(id, { name: name, description: description, organizationId: organization.id })} disabled={!name || !description} variant="contained">Update</Button>
                        <ConfirmationDialog
                            title="Delete shift?"
                            description={`Are you sure you want to delete shift ${name}?`}
                            action={() => deleteShift(id, name)}
                            isOpen={deleteDialogOpen}
                            closeDialog={() => setDeleteDialogOpen(false)} />
                    </Stack>
            }

        </Stack>
    );
}
