import React from "react";

import { Delete, KeyboardArrowDown, KeyboardArrowUp, List } from "@mui/icons-material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { getSectorName } from "../../../functions/sectorFunctions";
import ActionIcon from "../../atoms/ActionIcon";
import ConfirmationDialog from "../../molecules/ConfirmationDialog";

export default function AlarmSubFlowsTable({ alarmFlow, alarmSubFlows, deleteAlarmSubFlow, updateAlarmSubFlow, sectors }) {

    const navigate = useNavigate();

    const [ removalSubFlow, setRemovalSubFlow ] = React.useState(null);
    const [ deleteDialogOpen, setDeleteDialogOpen ] = React.useState(false);

    const handleClickDeleteDialogOpen = (subFlow) => {
        setRemovalSubFlow(subFlow);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setRemovalSubFlow(null);
        setDeleteDialogOpen(false);
    };

    const isFirst = (alarmSubFlow) => {
        return alarmSubFlow.id === alarmSubFlows[0].id;
    }

    const isLast = (alarmSubFlow) => {
        return alarmSubFlow.id === alarmSubFlows.slice(-1)[0].id;
    }

    const moveUp = (alarmSubFlow) => {
        const index = alarmSubFlows.indexOf(alarmSubFlow);
        const alarmSubFlowBefore = alarmSubFlows[index - 1];
        moveDown(alarmSubFlowBefore);
    }

    const moveDown = (alarmSubFlow) => {
        const index = alarmSubFlows.indexOf(alarmSubFlow);
        const alarmSubFlowNextId = alarmSubFlows[index + 1].id;
        updateAlarmSubFlow(alarmSubFlow.id, { sectorId: alarmSubFlow.sectorId, insertAfterSubFlowId: alarmSubFlowNextId});
    }

    return alarmSubFlows.length > 0 ? (
      <Box mt={4}>
        <TableContainer>
          <Table aria-label="Alarm Subflows">
            <TableHead>
              <TableRow>
                <TableCell>Sector</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alarmSubFlows.map((alarmSubFlow) => (
                <TableRow
                  key={alarmSubFlow.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {getSectorName(alarmSubFlow.sectorId, sectors)}
                  </TableCell>
                  <TableCell>
                    <ActionIcon
                      action={() => moveUp(alarmSubFlow)}
                      icon={<KeyboardArrowUp />}
                      label="Move Up"
                      disabled={isFirst(alarmSubFlow)}
                    />
                    <ActionIcon
                      action={() => moveDown(alarmSubFlow)}
                      icon={<KeyboardArrowDown />}
                      label="Move Down"
                      disabled={isLast(alarmSubFlow)}
                    />
                    <ActionIcon
                      action={() =>
                        navigate("/alarms/flows/subflows/shifts/overview", {
                          state: [alarmFlow, alarmSubFlow],
                        })
                      }
                      icon={<List />}
                      label="Manage Subflow"
                    />
                    <ActionIcon
                      action={() => handleClickDeleteDialogOpen(alarmSubFlow)}
                      icon={<Delete />}
                      label="Delete"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ConfirmationDialog
            title="Delete alarm subflow?"
            description={`Are you sure you want to delete the alarm subflow for sector ${
              removalSubFlow
                ? getSectorName(removalSubFlow.sectorId, sectors)
                : ""
            }?`}
            action={() =>
              deleteAlarmSubFlow(removalSubFlow.id, removalSubFlow.sectorId)
            }
            isOpen={deleteDialogOpen}
            closeDialog={handleDeleteDialogClose}
          />
        </TableContainer>
      </Box>
    ) : (
      <Box></Box>
    );
}