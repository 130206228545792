import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import { Table, TableBody, TableCell, TableContainer, TableRow, Toolbar} from "@mui/material";

import {useAuth} from "react-oidc-context";
import {createItem, deleteItem, getItems, updateItem} from "../../../APIHelper";
import {LoadingState} from "../../atoms/LoadingState";
import EditWanderMappingDialog from "../../molecules/wandermapping/EditWanderMappingDialog";
import {SET_FEEDBACK} from "../../../actions";
import Box from "@mui/material/Box";
import AddWanderMappingDialog from "../../molecules/wandermapping/AddWanderMappingDialog";
import ActionIcon from "../../atoms/ActionIcon";
import {Add, Delete, Edit} from "@mui/icons-material";
import TableHeader from "../../atoms/TableHeader";
import ConfirmationDialog from "../../molecules/ConfirmationDialog";

export default function DeviceWanderAreaForm() {

    const auth = useAuth();
    const initOrganization = useSelector(state => state.organization);
    const [mappings, setMappings] = useState([]);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [mappingToEdit, setMappingToEdit] = useState({});
    const [mappingToDelete, setMappingToDelete] = useState({});
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [mappingLoadingState, setMappingLoadingState] = useState(LoadingState.IDLE);

    useEffect(() => {
        getMappings();
        // eslint-disable-next-line
    }, [auth]);

    const getMappings = () => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/hardware/wander-areas?organizationId=" + initOrganization.id,
            setLoadingState: setMappingLoadingState,
            setItems: setMappings
        });
    }

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: {feedBackType: severity, feedBackMessage: message}
        });
    };

    const patchMapping = (mapping) => {
        console.log(mapping);
        const token = auth.user?.access_token;
        const patch = {
            uuid: mapping.uuid,
            groupId: mapping.groupId,
            label: mapping.label,
            wanderLevel: mapping.wanderLevel,
            organizationId: initOrganization.id,
        }
            updateItem({
                token: token,
                path: "/v3alpha/hardware/wander-areas/" + mapping.uuid,
                item: patch,
                onSuccess: () => {
                    setFeedback("info", `Mapping updated`);
                    getMappings();
                    handleEditClose();
                },
                onFailure: (message) => setFeedback("error", "Error while configuring Mapping: " + message)
            });
    }

    const postMapping = (mapping) =>{
        const token = auth.user?.access_token;
        const patch = {
            groupId: mapping.groupId,
            label: mapping.label,
            wanderLevel: mapping.wanderLevel,
            organizationId: initOrganization.id,
        }
            createItem({
                token: token,
                path: "/v3alpha/hardware/wander-areas",
                item: patch,
                onSuccess: () => {
                    setFeedback("info", `New Mapping created`);
                    getMappings();
                    handleClose();
                },
                onFailure: (message) => setFeedback("error", "Error while creating new mapping: " + message)
            });

    }

    const deleteMapping = (mapping) => {
        const token = auth.user?.access_token;
        deleteItem({
            token: token,
            path: "/v3alpha/hardware/wander-areas/" + mapping.uuid,
            onSuccess: () => {
                setFeedback("info", `mapping has been deleted`);
                getMappings();
            },
            onFailure: (message) => setFeedback("error", "Error while deleting the mapping: " + message)
        })

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleEditClose = () => {
        setMappingToEdit({});
        setEditOpen(false);
    };

    const handleDeleteOpen = (mapping) => {
        setMappingToDelete(mapping);
        setDeleteDialog(true);
    }
    const handleDeleteDialogClose = () =>{
        setDeleteDialog(false);
        setMappingToDelete({});
    }

    const handleEditClickOpen = (mapping) => {
        setMappingToEdit(mapping);
        setEditOpen(true);
    };

    const showMappings = mappingLoadingState === LoadingState.LOADED && mappings;

    return (
        <div>
            <Toolbar>
                <Box>
                    <ActionIcon action={() => handleClickOpen()} icon={<Add />} label="Create a new Mapping" />
                </Box>
            </Toolbar>
            <AddWanderMappingDialog
                isOpen={open}
                closeDialog={() => handleClose()}
                postMapping={(newMapping) => postMapping(newMapping)}
            />
            {showMappings && (
                <TableContainer>
                    <Table aria-label="Wander-Area Mappings">
                        <TableHeader columns={["Group-Id", "Label", "Wander Level", "Actions"]}/>
                        <TableBody>
                {mappings.map((mapping) => (
                    <TableRow
                        key={mapping.uuid}
                        sx={{"&:last-child td, &:last-child th": { border: 0 }}}>
                        <TableCell>{mapping.groupId}</TableCell>
                        <TableCell>{mapping.label}</TableCell>
                        <TableCell>{mapping.wanderLevel}</TableCell>
                       <TableCell>
                            <ActionIcon action={() => handleEditClickOpen(mapping)} icon={<Edit />} label="Edit" />
                            <ActionIcon action={() => handleDeleteOpen(mapping)} icon={<Delete />} label="Delete" />
                       </TableCell>
                    </TableRow>
                ))}
                        </TableBody>
                    </Table>
                    <EditWanderMappingDialog
                        isOpen={editOpen}
                        closeDialog={() => handleEditClose()}
                        mapping={mappingToEdit}
                        handleUpdate={(editedMapping) => patchMapping(editedMapping)}/>
                    <ConfirmationDialog
                        title="Delete Wander mapping?"
                        description={`Are you sure you want to delete this wander area mapping?`}
                        action={() => deleteMapping(mappingToDelete)}
                        isOpen={deleteDialog}
                        closeDialog={handleDeleteDialogClose} />
                </TableContainer>
            )}
        </div>
    );
}
