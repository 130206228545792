import React from "react"

import { Box } from "@mui/material"
import { Route, Routes } from "react-router-dom"

import AlarmFlowFormPanel from "../pages/AlarmFlowFormPanel"
import AlarmFlowsOverview from "../pages/AlarmFlowsOverview"
import AlarmSubFlowListPanel from "../pages/AlarmSubFlowListPanel"
import AlarmSubFlowShiftListPanel from "../pages/AlarmSubFlowShiftListPanel"
import AlarmsOverview from "../pages/AlarmsOverview"
import Dashboard from "../pages/Dashboard"
import Devices from "../pages/Devices"
import DevicesImport from "../pages/DevicesImport"
import ResidentDetails from "../pages/ResidentDetails"
import ResidentStateHistory from "../pages/ResidentStateHistory"
import ResidentStateHistoryOverview from "../organisms/residents/states/ResidentStateHistoryOverview"
import ResidentsOverview from "../pages/ResidentsOverview"
import ShiftFormPanel from "../pages/ShiftFormPanel"
import ShiftsOverview from "../pages/ShiftsOverview"
import TechnicalAlarmsOverview from "../pages/TechnicalAlarmsOverview"
import ShiftDetailsPage from "./alarms/shifts/ShiftDetailsPage"
import GatewayPage from "./devices/GatewayPage"
import HardwareSetPage from "./devices/HardwareSetPage"
import ResidentsConvertPage from "./residentsimport/ResidentsConvertPage"
import ResidentsImportPage from "./residentsimport/ResidentsImportPage"
import UsersOverview from "../pages/UsersOverview"
import DeviceWanderArea from "../pages/DeviceWanderArea"
import Integrations from "../pages/Integrations";

export default function Router({ drawerWidth }) {
    return (
        <Box
            sx={{
                width: `calc(100% - ${drawerWidth}px)`,
                ml: `${drawerWidth}px`,
                mt: `77px`,
            }}
        >
            <Routes>
                <Route path="devices/overview" element={<Devices />} />
                <Route path="devices/import" element={<DevicesImport />} />
                <Route path="devices/gateways/:gatewayId" element={<GatewayPage />} />
                <Route path="devices/hardwaresets/:hardwareSetId" element={<HardwareSetPage />} />
                <Route path="devices/alarms/overview" element={<TechnicalAlarmsOverview />} />
                <Route path="devices/wander" element={<DeviceWanderArea />} />
                <Route path="residents/overview" element={<ResidentsOverview />} />
                <Route path="residents/details/:residentId" element={<ResidentDetails />} /> 
                <Route path="residents/states/history" element={<ResidentStateHistory />} />
                <Route path="residents/states/:residentId" element={<ResidentStateHistoryOverview />} />
                <Route path="residents/convert" element={<ResidentsConvertPage />} />
                <Route path="residents/import" element={<ResidentsImportPage />} />
                <Route path="alarms/overview" element={<AlarmsOverview />} />
                <Route path="alarms/shifts/overview" element={<ShiftsOverview />} />
                <Route path="alarms/shifts/form" element={<ShiftFormPanel />} />
                <Route path="alarms/shifts/:shiftId" element={<ShiftDetailsPage />} />
                <Route path="alarms/flows/overview" element={<AlarmFlowsOverview />} />
                <Route path="alarms/flows/form" element={<AlarmFlowFormPanel />} />
                <Route path="alarms/flows/subflows/overview" element={<AlarmSubFlowListPanel />} />
                <Route path="alarms/flows/subflows/shifts/overview" element={<AlarmSubFlowShiftListPanel />} />
                <Route path="settings/integrations" element={<Integrations />} />
                <Route path="users/overview" element={<UsersOverview />} />
                <Route path="*" element={<Dashboard />} />
            </Routes>
        </Box>
    )
}
