import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Button, CircularProgress, Stack, TextField } from "@mui/material";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../../APIHelper";
import { SET_FEEDBACK } from "../../../actions";
import Page from "../../pages/Page";

export default function ResidentsImportPage() {
    const auth = useAuth();
    const dispatch = useDispatch()

    const initOrganization = useSelector((state) => state.organization)
    const [organization, setOrganization] = useState(initOrganization)
    const [organizations, setOrganizations] = useState([])
    const [fileInput, setFileInput] = useState("")
    const [busyLoading, setBusyLoading] = useState(false)

    const selectOrganization = (organization) => {
        if (organization) {
            setOrganization(organization)
        } else {
            setOrganization({ id: "", description: "" })
        }
    }

    const clearFileSelection = () => {
        setFileInput("")
    }

    const onFileChange = async (event) => {
        const token = auth.user?.access_token;
        setBusyLoading(true)
        const file = event.target.files[0]
        const content = await file.text()
        const response = await fetch(window.REACT_APP_API_URL + "/v3alpha/residents/import?organizationId=" + organization.id, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "text/csv",
            },
            body: content,
        })
        setBusyLoading(false)
        if (response.ok) {
            dispatch({
                type: SET_FEEDBACK,
                feedback: { feedBackType: "info", feedBackMessage: "Import residents succeeded" },
            })
        } else {
            const message = await response.text()
            dispatch({
                type: SET_FEEDBACK,
                feedback: { feedBackType: "error", feedBackMessage: "Import residents failed: " + message },
            })
        }
    }

    useEffect(() => {
        const token = auth.user?.access_token;
        if (!organizations.length) {
            getItems({
                token: token,
                path: "/v3alpha/organizations",
                setItems: setOrganizations,
            })
        }
    }, [organizations, auth])

    return (
        <Page title="Import Residents">
            <Stack spacing={2} mt={3} mb={3}>
                <Autocomplete
                    id="organizationac"
                    options={organizations}
                    disableClearable="true"
                    openOnFocus="true"
                    getOptionLabel={(option) => option.description}
                    value={organization}
                    onChange={(event, value) => selectOrganization(value)}
                    renderInput={(params) => <TextField {...params} id="organization" label="Organization" variant="outlined" />}
                />

                <Button variant="contained" component="label" disabled={!organization.id} onClick={clearFileSelection}>
                    Select CSV file
                    <input value={fileInput} hidden accept="text/csv" type="file" onChange={onFileChange} />
                </Button>
            </Stack>

            {busyLoading && <CircularProgress />}
        </Page>
    )
}
