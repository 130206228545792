import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function getStartOfPeriod(endOfPeriod, length) {
    var start = new Date(endOfPeriod.getTime());
    switch (length) {
        case "1d": start.setDate(start.getDate() - 1); break;
        case "1w": start.setDate(start.getDate() - 7); break;
        case "2w": start.setDate(start.getDate() - 14); break;
        case "1m": start.setMonth(start.getMonth() - 1); break;
        case "2m": start.setMonth(start.getMonth() - 2); break;
        default: start.setMonth(start.getMonth() - 2);
    }
    return start;
}

export default function PeriodSelector({ period, setPeriod }) {

    return (
        <FormControl>
            <InputLabel id="period-selectlabel">Period</InputLabel>
            <Select labelId="period-selectlabel" id="period" label="Period" value={period} onChange={(e) => setPeriod(e.target.value)}>
                <MenuItem value={"1d"}>One day</MenuItem>
                <MenuItem value={"1w"}>One week</MenuItem>
                <MenuItem value={"2w"}>Two weeks</MenuItem>
                <MenuItem value={"1m"}>One month</MenuItem>
                <MenuItem value={"2m"}>Two months</MenuItem>
            </Select>
        </FormControl>
    );

}