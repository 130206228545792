import React from "react";

import { TableCell, TableHead, TableRow } from "@mui/material";

export default function TableHeader({ columns }) {

	return (
		<TableHead>
			<TableRow>
				{columns.map(title => <TableCell>{title}</TableCell>)}
			</TableRow>
		</TableHead>
	);
}