import { Delete, Edit, Info } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { ButtonGroup, Card, CardActions, CardContent, CardMedia, Paper, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material/styles";
import React from "react";
import { getDeviceImage } from '../../../functions/DeviceImage';
import ActionIcon from '../../atoms/ActionIcon';
import BatteryStateIcon from "../../atoms/BatteryStateIcon";
import SignalStrengthIcon from "../../atoms/SignalStrengthIcon";

export default function DeviceCard({ device, handleUnlink, handleDetails, handleEdit }) {

    const theme = useTheme();

    const connectionStatus = () => {
        if (device.connectionStatus === "OFFLINE") {
            return <Tooltip title={"Device is offline"}><ErrorIcon color="error" /></Tooltip>
        } else if (device.connectionStatus === "UNKNOWN") {
            return <Tooltip title={"Device might not be properly installed"}><WarningIcon color="warning" /></Tooltip>
        } else {
            return <SignalStrengthIcon rssi={device.rssi} />
        }
    }

    const notPa = device.type !== "PA"

    return (
        <Card>
            <CardMedia
                sx={{ height: "125px", bgcolor: theme.palette.info.main, objectFit: "contain" }}
                image={getDeviceImage(device)}
                component="img"
                title={device.label} />
            <CardContent>
                <Box>
                    <Typography variant="h5" sx={{ display: 'flex', justifyContent: "center", mb: "8px" }}>
                        {device.label}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: "center", mr: "16px", ml: "16px" }}>
                        {notPa && <>
                        <Paper elevation={0} sx={{ width: "100%" }}>
                            <Typography variant="body1" align="center">
                                {connectionStatus()}
                            </Typography>
                            <Typography variant="body1" align="center">
                                {device.connectionStatus}
                            </Typography>
                        </Paper>
                        <Paper elevation={0} sx={{ width: "100%" }}>
                            <Typography variant="body1" align="center">
                                <BatteryStateIcon device={device} />
                            </Typography>
                            <Typography variant="body1" align="center">
                                {(device.batteryVoltage / 10).toFixed(1)} V
                            </Typography>
                        </Paper> </>}
                    </Box>
                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ display: "flex", justifyContent: "space-between" }}>
                <ActionIcon action={() => handleDetails(device)} icon={<Info color="info" />} label="Show details" />
                <ButtonGroup>
                    <ActionIcon action={() => handleEdit(device)} icon={<Edit />} label="Edit" />
                    {device.realm === "KADEX" && <ActionIcon action={() => handleUnlink(device)} icon={<Delete />} label="Unlink" />}
                    {device.realm === "AKUVOX" && <ActionIcon action={() => handleUnlink(device)} icon={<Delete />} label="Unlink" />}
                </ButtonGroup>
            </CardActions>
        </Card>
    );

}
