import * as React from 'react';
import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function GatewayTable({ gateways }) {

    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table aria-label="Gateway">
                <TableHead>
                    <TableRow>
                        <TableCell>Gateway</TableCell>
                        <TableCell>Realm</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Connectivity</TableCell>
                        <TableCell>Last activity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gateways.map((gateway) => (
                    <TableRow key={gateway.gatewayId}>
                        <TableCell>
                        <Link href="#" onClick={() => navigate(`/devices/gateways/${gateway.gatewayId}`)}>{gateway.gatewayId}</Link>
                        </TableCell>
                        <TableCell>
                            {gateway.realm}
                        </TableCell>
                        <TableCell>
                            {gateway.state}
                        </TableCell>
                        <TableCell>
                            {gateway.reachability}
                        </TableCell>
                        <TableCell>
                            {gateway.lastActivity}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}