import React, { useEffect, useState } from "react";

import { CircularProgress, Stack } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { getItem } from "../../../APIHelper";
import { useAuth } from "react-oidc-context";
import ActionIcon from "../../atoms/ActionIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HardwareSetDevicesOverview from "./HardwareSetDevicesOverview";
import { LoadingState } from "../../atoms/LoadingState";
import SensaraAlert from "../../atoms/SensaraAlert";

export default function HardwareSetPage() {

    const { hardwareSetId } = useParams()
    const auth = useAuth();
    const navigate = useNavigate()

    const [hardwareSetLoadingState, setHardwareSetLoadingState ] = useState(LoadingState.IDLE)
    const [hardwareSet, setHardwareSet] = useState(null)

    useEffect(() => {
      const token = auth.user?.access_token;
      getItem({
        token: token,
        path: "/v3alpha/hardware/sets/" + hardwareSetId,
        setLoadingState: setHardwareSetLoadingState,
        setItem: setHardwareSet,
      });

    }, [hardwareSetId, auth])
    
    const getCompleteHardwareSet = () => {
      hardwareSet.type = "set";
      return hardwareSet
    }

    const idle = hardwareSetLoadingState === LoadingState.IDLE;
    const loading = hardwareSetLoadingState === LoadingState.LOADING;
    const showHardwareSet = hardwareSetLoadingState === LoadingState.LOADED && hardwareSet;
    const noneFound = hardwareSetLoadingState === LoadingState.LOADED && !hardwareSet;
    const error = hardwareSetLoadingState === LoadingState.ERROR;

    return (
      <Stack ml={2}>
        {(idle || loading) && <CircularProgress />}
        {showHardwareSet && (
          <HardwareSetDevicesOverview hardwareSet={getCompleteHardwareSet()} />
        )}
        {noneFound && (
          <SensaraAlert severity="info" message="Hardware set does not exist" />
        )}
        {error && (
          <SensaraAlert severity="error" message="Hardware set could not be loaded."/>
        )}
        <Stack spacing={2} mt={5} direction="row">
          <ActionIcon
            action={() => navigate("/devices/overview")}
            icon={<ArrowBackIcon />}
            label="Back"
          />
        </Stack>
      </Stack>
    );
}
