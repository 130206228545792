import {Button, Card, CardActions, CardContent, CardMedia, Paper, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import React from "react";
export default function IntegrationCard({ integration, handleClickOpen, handleDeactivate, activation }) {

    const activated= (
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <Button sx={{ml:"16px", bgcolor: "#422045"}} variant="contained" onClick={handleDeactivate}>DeActivate</Button>
        </Box>
    );

    const deActivated= (
            <Button sx={{ml:"16px"}} variant="contained" onClick={handleClickOpen}>Activate</Button>
    );

    console.log(JSON.stringify(integration));
    return (
        <Card sx={{width:"375px", height:"375", display:"flex", flexDirection:"column",justifyContent:"flex-start"}}>
            <CardMedia
                sx={{ height: "125px", width:"auto", objectFit: "contain", alignSelf:"flex-start", ml:"16px",pt:"8px" }}
                image={integration.image}
                component="img"
                title={integration.name} />
            <CardContent sx={{p:"8px"}}>
                <Box>
                    <Typography variant="h5" sx={{ display: 'flex', justifyContent: "left", mb: "8px", ml:"16px" }}>
                        {integration.name}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: "center", mr: "16px", ml: "16px" }}>
                        <Paper elevation={0} sx={{ width: "100%" }}>
                            <Typography variant="body1" align="left">
                                {integration.description}
                            </Typography>
                        </Paper>
                    </Box>
                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ display: "flex", justifyContent: "flex-start",pb:"16px" }}>
                {activation && activated}
                {!activation && deActivated}
            </CardActions>
        </Card>
    );

}
