import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField } from "@mui/material";


export default function AddWanderMappingDialog({ isOpen, closeDialog, postMapping }) {

	const { register, handleSubmit, reset, getValues } = useForm();
	const [filled, setFilled] = useState(false);

	useEffect(() => {
		reset();
// eslint-disable-next-line
	}, [isOpen, closeDialog])

	function handleFilledChange(){
		if ((getValues("label") && getValues("groupId")) && getValues("wanderLevel")){
			setFilled(true);
		}
		else {
			setFilled(false)
		}
	}


	return (
		<form onSubmit={handleSubmit((newMapping) => postMapping(newMapping))} id="mappingForm">
			<Dialog open={isOpen} >
				<DialogTitle sx={{ display: "flex", alignItems: "center" }} id="add-mapping-dialog-title">
					Create a new Mapping
				</DialogTitle>
				<DialogContent>
				<DialogContentText>
						Make sure the Group ID exists before creating a mapping
					</DialogContentText>
					<DialogContentText>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<TextField variant="standard" label="GroupId" {...register(
								"groupId",{
									onChange: (e) => {handleFilledChange()}
								})
							}/>
						</FormControl>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<TextField variant="standard" label="WanderLevel" {...register(
								"wanderLevel",{
									onChange: (e) => {handleFilledChange()}
								})
							}/>
						</FormControl>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<TextField variant="standard" label="Label" {...register(
								"label",{
									onChange: (e) => {handleFilledChange()}
								})
							}/>
						</FormControl>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button type="submit" disabled={!filled} form="mappingForm">Save</Button>
					<Button onClick={closeDialog}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
}
