import React from "react";
import { Box, Typography } from "@mui/material";

export default function Detail({ title, description }) {

	const sharedProperties = {
		display: "inline-block",
		verticalAlign: "middle"
	};

	return (
		<Typography variant="body2" sx={{ margin: "0.5em" }}>
			<Box sx={{ ...sharedProperties, width: "10em", overflow: "hidden", fontWeight: "bold" }}>{title}</Box>
			<Box sx={sharedProperties}>{description}</Box>
		</Typography>
	);

}