import { createStore } from 'redux';
import {CLEAR_FEEDBACK, CLEAR_ORGANIZATION, SELECT_ORGANIZATION, SET_FEEDBACK} from "./actions";

const initOrg = { id: null, description: ''};
const initFeedback = { feedBackType: '', feedBackMessage: ''};
const initState = {organization : initOrg , feedback : initFeedback};

const userWorkContext = (state = initState, action) => {
    switch (action.type) {
        case SELECT_ORGANIZATION:
            return {
                ...state,
                organization: action.organization
            }
        case CLEAR_ORGANIZATION:
            return {
                ...state,
                organization: initOrg
            }
        case SET_FEEDBACK:
            return {
                ...state,
                feedback: action.feedback
            }
        case CLEAR_FEEDBACK:
            return {
                ...state,
                feedback: initFeedback
            }
        default:
            return state;
    }
}

export const store = createStore(userWorkContext);
