import React from "react";

import { Autocomplete, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import { toId } from "../../functions/stringFunctions";

export default function ItemAdder({ title, items, addItem }) {

	const [item, setItem] = React.useState(null);

	return (
		items.length > 0 ?
			<Stack direction="row" spacing={2}>
				<Autocomplete
					sx={{ minWidth: "16em" }}
					id={toId(title)}
					options={items}
					value={item}
					getOptionLabel={item => item.name ? item.name : item.id}
					onChange={(event, item) => setItem(item)}
					renderInput={(params) => (
						<TextField {...params} label={title} variant="outlined" />
					)}
				/>
				<Tooltip title="Add">
					<IconButton id="add" name="Add" variant="contained" disabled={item === null} onClick={() => addItem(item)}><Add /></IconButton>
				</Tooltip>
			</Stack>
			: <div />
	);

}
