import {Delete, Edit} from "@mui/icons-material";
import { ButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, {useEffect, useState} from "react";
import ActionIcon from '../../atoms/ActionIcon';
import UserDetailsDialog from "./UserDetailsDialog";
import {createItem, deleteItem, getItems} from "../../../APIHelper";
import {LoadingState} from "../../atoms/LoadingState";
import {useAuth} from "react-oidc-context";
import {SET_FEEDBACK} from "../../../actions";
import {useDispatch} from "react-redux";

export default function UserTable({ users, organization }) {
    const auth = useAuth();
    const [extension, setExtension] = useState({});
    const dispatch = useDispatch();
    const [userDetailsToShow, setUserDetailsToShow] = useState({});
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [organizationServer, setOrganizationServer] = useState({});
    // eslint-disable-next-line
    const [devicesLoadingState, setDevicesLoadingState] = useState(LoadingState.IDLE);
    const [orgServer, setOrgServer] = useState({});
    const [hasVoip, setHasVoip] = useState(false);

    useEffect(() => {
        if (orgServer.voipServerId != null){
            setHasVoip(true);
        }
        else{
            setHasVoip(false)
        }
    }, [orgServer]);

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };
    const getExtension = (user) => {
        const token = auth.user?.access_token;
        const url = "/v3alpha/user/"+ user.id ;
        getItems({
            token: token,
            path: url + "/extension",
            setLoadingState: setDevicesLoadingState,
            setItems: setExtension
        });
    }

    function postSoftPhone(user){
        const token = auth.user?.access_token;
        const post = {
            uuid: "UUID",
            friendlyName: user.name,
            organizationId: organization.id,
            productType: "PHONEAPP",
            type: "SoftPhoneDto",
            userId: user.id
        }
        createItem({
            token: token,
            path: "/v3alpha/hardware/devices/voip/softphone",
            item: post,
            setLoadingState: setDevicesLoadingState,
            onSuccess: () => {
                setFeedback("info", `SoftPhone created`);
                handleDetailsClose();
            },
            onFailure: (message) => setFeedback("error", "Error while creating softphone")
        })
    }

    const deleteSoftPhone = (user) =>{
        const token = auth.user?.access_token;
        deleteItem({
            token: token,
            path: "/v3alpha/hardware/devices/voip/delete/softphone/" + user.id,
            onSuccess: () => {
                setFeedback("info", "Device for user " + user.id + " is deleted");
                handleDetailsClose();
            },
            onFailure: (error) => {
                setFeedback("error", "Error while deleting device: " + error);
            }
        });
    }

    const getServer = () => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/voip-servers/organization/" + organization.id  ,
            setItems: setOrganizationServer,
        });
    }

    const handleDetailsOpen = (user) => {
        getExtension(user);
        getServer();
        setUserDetailsToShow(user);
        setDetailsOpen(true);
        checkVoipStatus();
    };

    const checkVoipStatus = () => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/organizations/" + organization.id + "/voip-integrations",
            setLoadingState: setDevicesLoadingState,
            setItems: setOrgServer,
        });
    }

    const handleDetailsClose = () => {
        setUserDetailsToShow({});
        setExtension({});
        setDetailsOpen(false);
    };

    return (
        <div>
        <TableContainer>
            <Table aria-label="User">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            User
                        </TableCell>
                        <TableCell>
                            Sectors
                        </TableCell>
                        <TableCell>
                            Roles
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                        <TableCell>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell sx={{ maxWidth: "250px" }}>
                                <Typography variant="h6" noWrap>{user.name}</Typography>
                                <Typography variant="h7" noWrap>{user.email}</Typography>
                            </TableCell>
                            <TableCell sx={{ maxWidth: "100px" }}>
                                {user.sectors.items.map((sector) => (
                                    <Typography>
                                        {sector.name}
                                    </Typography>
                                ))}
                            </TableCell>
                            <TableCell sx={{ maxWidth: "100px" }}>
                                {user.roles.map((role) => (
                                    <Typography>
                                        {role.description}
                                    </Typography>
                                ))}
                            </TableCell>
                            <TableCell sx={{ maxWidth: "100" }}>
                                {user.status}
                            </TableCell>
                            <TableCell sx={{ maxWidth: "50" }}>
                                <ButtonGroup>
                                    <ActionIcon icon={<Edit />} action={() => handleDetailsOpen(user)} label="Edit User" />
                                    <ActionIcon icon={<Delete />} label="Remove user" />
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            <UserDetailsDialog
                user={userDetailsToShow}
                extension={extension}
                isOpen={detailsOpen}
                closeDialog={() => handleDetailsClose()}
                createSoftPhone={() => postSoftPhone(userDetailsToShow)}
                deleteSoftPhone={() => deleteSoftPhone(userDetailsToShow)}
                organizationServer={organizationServer}
                hasVoip={hasVoip}
            />
        </div>
    );

}
