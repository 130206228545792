import React from "react";
import { Card, CardContent } from "@mui/material";
import { dayMap, reformatTime } from "../../../functions/temporalFunctions";
import Detail from "../../atoms/Detail";

export default function AlarmFlowView({ alarmFlow }) {

	return (
		<Card>
			<CardContent>
				<Detail title="Active days" description={`from ${dayMap[alarmFlow.startDay]} to ${dayMap[alarmFlow.endDay]}`} />
				<Detail title="Active times" description={`between ${reformatTime(alarmFlow.startTime)} and ${reformatTime(alarmFlow.endTime)}`} />
			</CardContent>
		</Card>
	)
}
