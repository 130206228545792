import React from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Delete, Edit, List } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import APIHelper from "../../../../APIHelper";
import { SET_FEEDBACK } from "../../../../actions";
import ActionIcon from "../../../atoms/ActionIcon";
import TableHeader from "../../../atoms/TableHeader";
import ConfirmationDialog from "../../../molecules/ConfirmationDialog";

export default function ShiftsOverviewTable({ shifts, setDeletedShiftId }) {

    const auth = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [removalShift, setRemovalShift] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const handleClickDeleteDialogOpen = (shift) => {
        setRemovalShift(shift);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setRemovalShift(null);
    };

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };

    const deleteShift = (shift) => {
        const token = auth.user?.access_token;
        APIHelper.deleteItem({
            token: token,
            path: `/v3alpha/shifts/${shift.id}`,
            onSuccess: () => {
                setFeedback("info", `Shift ${shift.name} is deleted`);
                setDeletedShiftId(shift.id);
            },
            onFailure: (details) => setFeedback("error", `Could not delete shift ${shift.name}: ${details}`)
        })
    };

    return (
        <TableContainer>
            <Table aria-label="Shifts">
                <TableHeader columns={["Name", "Description", "Actions"]} />
                <TableBody>
                    {shifts.map((shift) => (
                        <TableRow key={shift.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>{shift.name}</TableCell>
                            <TableCell>{shift.description}</TableCell>
                            <TableCell>
                                <ActionIcon action={() => navigate("/alarms/shifts/form", { state: shift })} icon={<Edit />} label="Edit" />
                                <ActionIcon action={() => navigate(`/alarms/shifts/${shift.id}`)} icon={<List />} label="Show details" />
                                <ActionIcon action={() => handleClickDeleteDialogOpen(shift)} icon={<Delete />} label="Delete" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ConfirmationDialog
                title="Delete shift?"
                description={`Are you sure you want to delete shift ${removalShift ? removalShift.name : ""}?`}
                action={() => deleteShift(removalShift)}
                isOpen={deleteDialogOpen}
                closeDialog={handleDeleteDialogClose} />
        </TableContainer>
    );
}