import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { toId } from "../../functions/stringFunctions";

export default function ActionIcon({ action, icon, label, disabled = false }) {

    return (
        <Tooltip title={label}>
            <IconButton id={toId(label)} name={label} onClick={action} disabled={disabled} variant="contained">
                {icon}
            </IconButton>
        </Tooltip>
    );
}