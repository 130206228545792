import React, { useState} from "react";

import { useAuth } from "react-oidc-context";
import {LoadingState} from "../atoms/LoadingState";
import {getItems} from "../../APIHelper";
import SectorFilterMenu from "../molecules/SectorFilterMenu";
import LoadingMessages from "../molecules/LoadingMessages";
import AlarmsOverviewTable from "../organisms/alarms/AlarmsOverviewTable";
import Page from "./Page";

export default function AlarmsOverview() {

    const auth = useAuth();

    const [ selectedSector, setSelectedSector ] = useState("");
    const [ alarms, setAlarms ] = useState([]);
    const [ loadingState, setLoadingState ] = useState(LoadingState.IDLE);

    const getAlarmsForSector = (sector) => {
        const token = auth.user?.access_token;
        setSelectedSector(sector);
        getItems({
            token: token,
            path: "/informationserver/proapi/alarms/" + sector.id,
            setLoadingState: setLoadingState,
            setItems: (alarms) => {
                alarms.sort((a1, a2) => a2.timestampUtc - a1.timestampUtc);
                setAlarms(alarms);
            }
        });
    };

    return (
        <Page title={"Alarms" + (selectedSector ? " of " + selectedSector.name : "")}
            filters={<SectorFilterMenu onSelectSector={getAlarmsForSector} />}>
            <LoadingMessages loadingState={loadingState} entries={alarms} messages={{
                idle: "Please select a sector from the list.",
                noEntriesFound: "There are currently no alarms for this sector.",
                error: "The alarms for this sector could not be loaded."
            }} />
            { loadingState === LoadingState.LOADED && alarms.length > 0
                && <AlarmsOverviewTable alarms={alarms} /> }
        </Page>
    );
}
