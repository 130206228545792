import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Button, CircularProgress, Stack, TextField } from "@mui/material";
import download from "downloadjs";
import { useAuth } from "react-oidc-context";
import { getItems } from "../../../APIHelper";
import { SET_FEEDBACK } from "../../../actions";
import Page from "../../pages/Page";

export default function ResidentsConvertPage() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const initOrganization = useSelector((state) => state.organization);

    const [organization, setOrganization] = useState(initOrganization);
    const [organizations, setOrganizations] = useState([]);
    const [residentFileInput, setResidentFileInput] = useState("");
    const [housingFileInput, setHousingFileInput] = useState("");
    const [sectorFileInput, setSectorFileInput] = useState("");
    const [dapFileInput, setDapFileInput] = useState("");
    const [busyLoading, setBusyLoading] = useState(false);

    const selectOrganization = (organization) => {
        if (organization) {
            setOrganization(organization)
        } else {
            setOrganization({ id: "", description: "" })
        }
    }

    const upload = async () => {
        const token = auth.user?.access_token;
        setBusyLoading(true)

        const formData = new FormData()
        formData.append("organizationId", organization.id)
        formData.append("residentDbDump", residentFileInput)
        formData.append("housingUnitDbDump", housingFileInput)
        formData.append("sectorDbDump", sectorFileInput)
        formData.append("dapsDbDump", dapFileInput)

        const response = await fetch(window.REACT_APP_API_URL + "/v3alpha/residents/importdumps", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: formData,
        })

        setBusyLoading(false)
        if (response.ok) {
            const mimeType = response.headers.get("content-type")
            const blob = await response.blob()
            download(blob, "importResult.csv", mimeType)
        } else {
            const message = await response.text()
            dispatch({
                type: SET_FEEDBACK,
                feedback: { feedBackType: "error", feedBackMessage: "Could not process conversion response: " + message },
            })
        }
    }

    useEffect(() => {
        const token = auth.user?.access_token;
        if (!organizations.length) {
            getItems({
                token: token,
                path: "/v3alpha/organizations",
                setItems: setOrganizations,
            })
        }
    }, [organizations, auth])

    return (
        <Page title="Convert Resident database dumps">
            <Stack spacing={2}>
                <Autocomplete
                    id="organizationac"
                    options={organizations}
                    disableClearable="true"
                    openOnFocus="true"
                    getOptionLabel={(option) => option.description}
                    value={organization}
                    onChange={(event, value) => selectOrganization(value)}
                    renderInput={(params) => <TextField {...params} id="organization" label="Organization" variant="outlined" />}
                />

                <TextField
                    helperText="residents dump"
                    accept="text/csv"
                    type="file"
                    disabled={!organization.id}
                    onChange={(event) => setResidentFileInput(event.target.files[0])}
                />
                <TextField
                    helperText="housingUnits dump"
                    accept="text/csv"
                    type="file"
                    disabled={!organization.id}
                    onChange={(event) => setHousingFileInput(event.target.files[0])}
                />
                <TextField helperText="sectors dump" accept="text/csv" type="file" disabled={!organization.id} onChange={(event) => setSectorFileInput(event.target.files[0])} />
                <TextField helperText="daps dump" accept="text/csv" type="file" disabled={!organization.id} onChange={(event) => setDapFileInput(event.target.files[0])} />

                <Button variant="outlined" disabled={!organization.id} onClick={upload}>
                    Upload
                </Button>
            </Stack>

            {busyLoading && <CircularProgress />}
        </Page>
    )
}
