export const retrieveSectors = (token, organization) => {
    return fetch(window.REACT_APP_API_URL + "/v3/sectors" + (organization.id ? "?organizationId=" + organization.id : ""), {
        headers: {
            "Authorization": "Bearer " + token
        }
    });
}

export const getSectorName = (sectorId, sectors) => {
    const sec = sectors.find(s => s.id === sectorId);
    if (sec) {
        return sec.name;
    } else {
        return "UNKNOWN";
    }
}