import React from "react";

import { Link as RouterLink, useLocation } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export default function MenuItem({ link, icon, title, rootPath }) {

    const location = useLocation();
    const selected = rootPath ? location.pathname.startsWith(rootPath) : location.pathname === link;

    return (
        <ListItemButton component={RouterLink} to={link} selected={selected}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primaryTypographyProps={icon ? { fontWeight: 600 } : {}} primary={title} />
        </ListItemButton>
    );

}