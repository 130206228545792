import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import Detail from '../../atoms/Detail';

export default function HardwareSetOverview({ hardwareSet }) {

    return (
      <Card>
        <CardContent>
          <Detail title="name" description={hardwareSet.name} />
          <Detail title="state" description={hardwareSet.state} />
        </CardContent>
      </Card>
    );
}