import React from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function ImportedDevicesTable({ devices }) {

    return (
        <TableContainer>
            <Table aria-label="Devices">
                <TableHead>
                    <TableRow>
                        <TableCell>Mac</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Hardware set</TableCell>
                        <TableCell>Realm</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {devices.map((device) => (
                        <TableRow>
                            <TableCell>{device.deviceId}</TableCell>
                            <TableCell>{device.label}</TableCell>
                            <TableCell>{device.type}</TableCell>
                            <TableCell>{device.location}</TableCell>
                            <TableCell>{device.hardwareSet ? device.hardwareSet.name : ""}</TableCell>
                            <TableCell>{device.realm}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

 }