import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from './store'
import { AuthProvider } from "react-oidc-context";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const oidcConfig = {
    authority: window.REACT_APP_AUTH_URL,
    client_id: "sensara-care-portal",
    redirect_uri: window.REACT_APP_REDIRECT_URI,
};

root.render(
    <AuthProvider {...oidcConfig}>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
