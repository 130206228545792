import React from "react";
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { Tooltip } from "@mui/material";

export default function BatteryStateIcon({ device }) {

    if (device.connectionStatus === "OFFLINE" || device.connectionStatus === "UNKNOWN") {
        return <Tooltip title={`No battery information available`}><BatteryUnknownIcon color="error" /></Tooltip>;
    }

        if ((device.location === "BED" && device.type === "BS") || (device.realm === "AKUVOX"))
        {
            if (device.connectionStatus === "OFFLINE" || device.connectionStatus === "UNKNOWN") {
                return <Tooltip title={`Sensor might not be connected to a power socket`}><PowerOffIcon color="error" /></Tooltip>;
            } else {
                return <Tooltip title={`Sensor connected to power socket`}><PowerIcon color="success" /></Tooltip>;
            }
        // } else {
        //     return <Tooltip title={`No battery information available`}><BatteryUnknownIcon color="error"/></Tooltip>;
    } else if (device.batteryStatus === "OK") {
        return <Tooltip title={`Good, battery is still a good state (${device.batteryStatus})`}><Battery6BarIcon color="success" /></Tooltip>;
    } else if (device.batteryStatus === "LOW") {
        return <Tooltip title={`Low, replace as soon as possible (${device.batteryStatus})`}><Battery3BarIcon color="warning" /></Tooltip>;
    } else if (device.batteryStatus === "CRITICAL") { 
        return <Tooltip title={`Critical, now (${device.batteryStatus})`}><Battery1BarIcon color="error" /></Tooltip>;
    } else {
        return <Tooltip title={`Unknown; please check the device (${device.batteryStatus})`}><BatteryUnknownIcon color="error" /></Tooltip>;
    }

};
