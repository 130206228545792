const deviceImages = {
    // Kadex
    kadexDoorSensor: require("../assets/devices/kadex/door-motion-sensor.png"),
    kadexMotionSensor: require("../assets/devices/kadex/motion-sensor.png"),
    kadexVos: require("../assets/devices/kadex/vos.png"),
    kadexAlarmButton: require("../assets/devices/kadex/alarm-button.png"),
    // Ewig
    ewigAlarmButton: require("../assets/devices/ewig/alarm-button.png"),
    ewigDoorSensor: require("../assets/devices/ewig/door-sensor.png"),
    ewigMotionSensor: require("../assets/devices/ewig/motion-sensor.png"),
    // AKUVOX
    akuvoxVosUnit: require("../assets/devices/akuvox/akuvox.png"),
    // Generic
    chairSensor: require("../assets/devices/kadex/chair-sensor.png"),
    pullcord: require("../assets/devices/kadex/pullcord.png"),
    qsBedSensor: require("../assets/devices/emfit/qs-square.png"),
    //phone
    personalAlarm: require("../assets/devices/phone/smartphone.png"),

    unknown: require("../assets/devices/unknown.png")
};

export const getDeviceImage = (device) => {
    if (device.type === null || device.type === undefined) {
        return deviceImages.unknown
    } else if (device.realm === "AKUVOX") {
        return deviceImages.akuvoxVosUnit;
    } else if (device.type === "BS") {
        return deviceImages.qsBedSensor;
    } else if (device.type === "AL") {
        if (device.realm === "GREENPEAK") {
            return device.location === "ALARM_BUTTON" ? deviceImages.ewigAlarmButton : deviceImages.kadexVos;
        } else {
            return device.location === "ALARM_BUTTON" ? deviceImages.kadexAlarmButton : deviceImages.kadexVos;
        }
    } else if (device.type === "DR") {
        if (device.realm === "GREENPEAK") {
            return device.location === "PULL_CORD" ? deviceImages.pullcord : deviceImages.ewigDoorSensor;
        } else {
            return device.location === "PULL_CORD" ? deviceImages.pullcord : deviceImages.kadexDoorSensor;
        }
    } else if (device.type === "PA") {
        return deviceImages.personalAlarm;
    } else if (device.type === "CS") {
        return deviceImages.chairSensor;
    } else if (device.type === "MV") {
        if (device.realm === "GREENPEAK") {
            return deviceImages.ewigMotionSensor;
        } else {
            return device.location === "EXTERNAL_DOOR" ? deviceImages.kadexDoorSensor : deviceImages.kadexMotionSensor;
        }
    } else {
        return deviceImages.unknown
    }
}
