import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

import { LoadingState } from "../atoms/LoadingState";
import SelectableItemList from "./SelectableItemList";
import SensaraAlert from "../atoms/SensaraAlert";
import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import FilterMenu from "./FilterMenu";

export default function SectorFilterMenu({ onSelectSector }) {

    const auth = useAuth();
    const organization = useSelector(state => state.organization);
    const [sectors, setSectors] = useState([]);
    const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

    useEffect(() => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3/sectors" + (organization.id ? "?organizationId=" + organization.id : ""),
            setLoadingState: setLoadingState,
            setItems: setSectors,
        })
    }, [auth, organization]);

    return (
        <FilterMenu>
            {loadingState === LoadingState.LOADING
                && <CircularProgress />}
            {loadingState === LoadingState.LOADED
                && <SelectableItemList items={sectors.sort((s1, s2) => s1.name.localeCompare(s2.name))} onSelect={onSelectSector} />}
            {loadingState === LoadingState.ERROR
                && <SensaraAlert severity="error" message="The sectors could not be loaded." />}
        </FilterMenu>
    );

}