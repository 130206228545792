import React from "react";

import {
  Delete, KeyboardArrowDown, KeyboardArrowUp
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";

import { getShiftName } from "../../../functions/shiftFunctions";
import ActionIcon from "../../atoms/ActionIcon";
import ConfirmationDialog from "../../molecules/ConfirmationDialog";

export default function AlarmSubFlowShiftsTable({
  alarmSubFlow,
  alarmSubFlowShifts,
  updateAlarmSubFlowShifts,
  organizationShifts,
}) {
  const [removalSubFlowShift, setRemovalSubFlowShift] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleClickDeleteDialogOpen = (subFlowShift) => {
    setRemovalSubFlowShift(subFlowShift);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setRemovalSubFlowShift(null);
    setDeleteDialogOpen(false);
  };

  const isDelayValid = (d) => d >= 0;

  const updateDelay = (alarmSubFlowShift, newDelay) => {
    if (newDelay && isDelayValid(newDelay)) {
      alarmSubFlowShift.delay = newDelay;
      updateAlarmSubFlowShifts(alarmSubFlow.id, alarmSubFlowShifts);
    }
  };

  const isFirst = (alarmSubFlowShift) => {
    return alarmSubFlowShifts.indexOf(alarmSubFlowShift) === 0;
  };

  const isLast = (alarmSubFlowShift) => {
    return (
      alarmSubFlowShifts.indexOf(alarmSubFlowShift) ===
      alarmSubFlowShifts.length - 1
    );
  };

  const moveUp = (alarmSubFlowShift) => {
    const index = alarmSubFlowShifts.indexOf(alarmSubFlowShift);
    [alarmSubFlowShifts[index - 1], alarmSubFlowShifts[index]] = [
      alarmSubFlowShifts[index],
      alarmSubFlowShifts[index - 1],
    ];
    updateAlarmSubFlowShifts(alarmSubFlow.id, alarmSubFlowShifts);
  };

  const moveDown = (alarmSubFlowShift) => {
    const index = alarmSubFlowShifts.indexOf(alarmSubFlowShift);
    [alarmSubFlowShifts[index + 1], alarmSubFlowShifts[index]] = [
      alarmSubFlowShifts[index],
      alarmSubFlowShifts[index + 1],
    ];
    updateAlarmSubFlowShifts(alarmSubFlow.id, alarmSubFlowShifts);
  };

  const deleteAlarmSubFlowShift = () => {
    const index = alarmSubFlowShifts.indexOf(removalSubFlowShift);
    alarmSubFlowShifts.splice(index, 1);
    updateAlarmSubFlowShifts(alarmSubFlow.id, alarmSubFlowShifts);
  };

  return alarmSubFlowShifts.length > 0 ? (
    <Box mt={4}>
      <TableContainer>
        <Table aria-label="Alarm Subflow shifts">
          <TableHead>
            <TableRow>
              <TableCell>Shift</TableCell>
              <TableCell>Delay (min)</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarmSubFlowShifts.map((alarmSubFlowShift, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {getShiftName(alarmSubFlowShift.shiftId, organizationShifts)}
                </TableCell>

                <TableCell>
                  <FormControl>
                    <TextField
                      id="delay"
                      label="Delay"
                      variant="outlined"
                      value={alarmSubFlowShift.delay}
                      required="true"
                      type="number"
                      min="0"
                      max="10080"
                      floatingLabelText="Delay"
                      style={{ width: 150 }}
                      onChange={(event) =>
                        isDelayValid(event.target.value) &&
                        updateDelay(alarmSubFlowShift, event.target.value)
                      }
                    />
                  </FormControl>
                </TableCell>
                <TableCell>
                  <ActionIcon
                    action={() => moveUp(alarmSubFlowShift)}
                    icon={<KeyboardArrowUp />}
                    label="Move Up"
                    disabled={isFirst(alarmSubFlowShift)}
                  />
                  <ActionIcon
                    action={() => moveDown(alarmSubFlowShift)}
                    icon={<KeyboardArrowDown />}
                    label="Move Down"
                    disabled={isLast(alarmSubFlowShift)}
                  />
                  <ActionIcon
                    action={() =>
                      handleClickDeleteDialogOpen(alarmSubFlowShift)
                    }
                    icon={<Delete />}
                    label="Delete"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ConfirmationDialog
          title="Delete alarm subflow shift coupling?"
          description={`Are you sure you want to delete the alarm subflow coupling to shift ${
            removalSubFlowShift
              ? getShiftName(removalSubFlowShift.shiftId, organizationShifts)
              : ""
          }?`}
          action={deleteAlarmSubFlowShift}
          isOpen={deleteDialogOpen}
          closeDialog={handleDeleteDialogClose}
        />
      </TableContainer>
    </Box>
  ) : (
    <Box></Box>
  );
}
