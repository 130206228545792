import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export default function AlarmsOverviewTable({alarms}) {    

    return (
        <TableContainer>
            <Table aria-label="Alarms">
                <TableHead>
                    <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>Person</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Raised</TableCell>
                        <TableCell>Last update</TableCell>
                        <TableCell>Escalated</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alarms.map((alarm) => (
                        <TableRow
                            key={alarm.uuid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{alarm.location}</TableCell>
                                <TableCell>{alarm.resident.name}</TableCell>
                                <TableCell>{alarm.internalAlarmStateName}</TableCell>
                                <TableCell>{alarm.internalAlarmName}</TableCell>
                                <TableCell>{new Date(alarm.timestampUtc).toISOString()}</TableCell>
                                <TableCell>{new Date(alarm.lastUpdateUtc).toISOString()}</TableCell>
                                <TableCell>{String(alarm.escalated)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>   
    );
}