import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Switch,
    TextField
} from "@mui/material";


export default function EditDeviceDialog({isOpen, closeDialog, device, patchDevice, mappings}) {

    const {register, handleSubmit, reset} = useForm({defaultValues: device});
    const [ble, setBle] = useState(device.profile === "BLE");

    useEffect(() => {
        reset({
            defaultValues: device
        })
        setBle(device.profile === "BLE");
        // eslint-disable-next-line
    }, [closeDialog, isOpen, reset])

    const isKdxPb = (device.realm === "KADEX") && (device.type === "AL") && (device.location === "ALARM_BUTTON");

    const types = [
        {value: "MV", label: "Motion sensor"},
        {value: "BS", label: "Emfit QS Bed sensor"},
        {value: "CS", label: "Chair sensor"},
        {value: "DR", label: "Open/close sensor or Pull cord"},
		{ value: "AL", label: "Alarm button" },
		{ value: "PA", label: "Personal Alarm" }
    ];

    const locations = [
        {value: "ALARM_BUTTON", label: "Alarm button"},
        {value: "BED", label: "Bed"},
        {value: "CHAIR", label: "Chair"},
        {value: "EXTERNAL_DOOR", label: "External Door"},
        {value: "HALL", label: "Hallway"},
        {value: "HOBBY_ROOM", label: "Hobby room"},
        {value: "KITCHEN", label: "Kitchen"},
        {value: "LIVING_ROOM", label: "Living room"},
        {value: "PULL_CORD", label: "Pull cord"},
        {value: "TOILET", label: "Toilet"},
    ];

    //
	const isNotAkuvox = device.realm !== "AKUVOX";
	const isAkuvox = device.realm ==="AKUVOX";


    const handleToggle= (e) => {
        setBle(e.target.checked);
    };

	return (
		<form onSubmit={handleSubmit((device) => patchDevice(device, ble))} id="editDeviceForm">
			<Dialog open={isOpen} >
				<DialogTitle sx={{ display: "flex", alignItems: "center" }} id="add-device-dialog-title">
					{device.deviceId}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Make sure the device is online before making changes.
					</DialogContentText>
					{isNotAkuvox &&
					<DialogContentText>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<TextField defaultValue={device.deviceId} variant="standard" sx={{ display: 'none' }} {...register("deviceId")} />
							<TextField defaultValue={device.realm} variant="standard" sx={{ display: 'none' }} {...register("realm")} />
							<TextField variant="standard" defaultValue={device.label} label="Label" {...register("label")} />
						</FormControl>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1, minWidth: 150 }}>
							<InputLabel>Type</InputLabel>
							<Select defaultValue={device.type} {...register("type")}>
								{types.map((type) => (
									<MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1, minWidth: 150 }}>
							<InputLabel>Location</InputLabel>
							<Select defaultValue={device.location} {...register("location")}>
								{locations.map((location) => (
									<MenuItem key={location.value} value={location.value}>{location.label}</MenuItem>
								))}
							</Select>
						</FormControl>
						{isKdxPb &&<Box sx={{display: "flex", flexDirection: "column"}}>
							<FormControl variant="standard" sx={{width: "75%", mt: 1, minWidth: 150}}>
								<InputLabel>Wander Area</InputLabel>
								<Select defaultValue={device.wanderAreaId} {...register("mappingUuid")}>
									{mappings.map((mapping) => (
										<MenuItem key={mapping.uuid} value={mapping.uuid}>{mapping.label}</MenuItem>
									))}
									<MenuItem sx={{color:"#e8044c", fontWeight: 500}} value={null}>Remove</MenuItem>
								</Select>
							</FormControl>
                            <InputLabel sx={{top: 10}}>Location Tracking</InputLabel>
                            <FormControl>
                                <Switch sx={{left:-13, mt:1}} onChange={handleToggle} defaultValue={ble} checked={ble}> BLE</Switch>
                            </FormControl>

                        </Box>}
					</DialogContentText>
				}
					{isAkuvox &&
				<DialogContentText>
					<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
						<TextField defaultValue={device.deviceId} variant="standard" sx={{ display: 'none' }} {...register("deviceId")} />
						<TextField defaultValue={device.realm} variant="standard" sx={{ display: 'none' }} {...register("realm")} />
						<TextField variant="standard" defaultValue={device.label} label="Name" {...register("friendlyName")} />
						{/*<TextField variant="standard" label="Username" {...register("userName")} />*/}
						{/*<TextField variant="standard" label="Password" {...register("password")} />*/}
						{/*<TextField variant="standard" defaultValue={device.sipUserId} label="Sip UserId" {...register("sipUserId")} />*/}
					</FormControl>
				</DialogContentText>
					}
				</DialogContent>
				<DialogActions>
                    <Button type="submit" form="editDeviceForm">Save</Button>
					<Button onClick={closeDialog}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
}
