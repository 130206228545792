import React from "react";

import { Stack } from "@mui/material";

import SensaraAlert from "../../atoms/SensaraAlert";

export default function ImportErrorList({ errors }) {
    
    return (
        <Stack sx={{ margin: "0.5em" }} spacing={1}>
            { errors.map((error) => <SensaraAlert severity="error" message={error} />) }
        </Stack>
    );

}