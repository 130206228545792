const integrationImages = {
    sipImage: require("../assets/integrations/sip.png"),
};

const integrationDetails =[
    {
        id: "sip",
        image: integrationImages.sipImage,
        name: "Sip Integration",
        description: "Activate a connection to the SIP server to allow Voice over IP calls with supported devices."
    }
    ];

export const getAllIntegrations = () => {
    return integrationDetails;
}
