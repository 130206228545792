import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function SubMenu({ children, icon, title, rootPath }) {

    const location = useLocation();
    const isSelected = location.pathname.startsWith(rootPath);
    const [ foldedOut, setFoldedOut ] = useState(isSelected);

    return (
        <div>
            <ListItemButton onClick={() => setFoldedOut(!foldedOut)} selected={isSelected}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontWeight: 600 }} primary={title} />
                { foldedOut ? <ExpandLess /> : <ExpandMore /> }
            </ListItemButton>
            <Collapse in={foldedOut} timeout="auto" unmountOnExit>
                <List component="div" sx={{ pl: 7 }}>
                    {children}
                </List>
            </Collapse>
        </div>
    );

}