import React from "react";

import { Stack } from "@mui/material";

import PageHeader from "../atoms/PageHeader";
import FilterMenu from "../molecules/FilterMenu";

export default function Page({ children, filters = <FilterMenu />, title, variant = "wide" }) {

    return (
        <Stack direction="row" spacing={1}>
            {filters}
            <Stack sx={{ padding: "0.5em", minWidth: (variant === "slim" ? "50%" : "65%") }} spacing={2}>
                <PageHeader>{title}</PageHeader>
                {children}
            </Stack>
        </Stack>
    );

}
