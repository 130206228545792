import {
  Box,
  Button, Select,
  Stack,
  TextField
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function AlarmSubFlowShiftAddition({
  alarmSubflow,
  updateAlarmSubFlowShifts,
  organizationShifts,
}) {
  const organization = useSelector((state) => state.organization);

  const alarmSubflowShifts = alarmSubflow.shifts;
  const [shift, setShift] = useState("");
  const [delay, setDelay] = useState(null);
  const isDelayValid = (d) => d >= 0;

  const valid = shift && delay && isDelayValid(delay) && organization.id;

  const addAlarmSubFlowShift = () => {
    alarmSubflowShifts.push({ shiftId: shift, delay: delay });
    updateAlarmSubFlowShifts(alarmSubflow.id, alarmSubflowShifts);
};

  return organizationShifts.length > 0 ? (
    <Box mt={4}>
      <Stack spacing={2} direction="row">
        <FormControl required="true">
          <InputLabel id="sector-select-label">Shift</InputLabel>
          <Select
            labelId="shift-select-label"
            id="shift"
            value={shift}
            label="Shift"
            autoWidth="true"
            required="true"
            style={{ width: 150 }}
            onChange={(event) => setShift(event.target.value)}
          >
            {organizationShifts.map((selectionShift) => (
              <MenuItem key={selectionShift.id} value={selectionShift.id}>
                {selectionShift.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            id="delay"
            label="Delay (min)"
            variant="outlined"
            value={delay}
            required="true"
            type="number"
            min="0"
            max="10080"
            style={{ width: 150 }}
            floatingLabelText="Delay"
            onChange={(event) =>
              isDelayValid(event.target.value) && setDelay(event.target.value)
            }
          />
        </FormControl>
        <Button
          id="add"
          name="Add"
          onClick={addAlarmSubFlowShift}
          disabled={!valid}
          variant="contained"
        >
          Add
        </Button>
      </Stack>
    </Box>
  ) : (
    <Box></Box>
  );
}
