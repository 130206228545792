import React from "react";
import { ReactComponent as Default } from "../../assets/residentstates/default.svg";
import { ReactComponent as InBathroom } from "../../assets/residentstates/in-bathroom.svg";
import { ReactComponent as InBed } from "../../assets/residentstates/in-bed.svg";
import { ReactComponent as InChair } from "../../assets/residentstates/in-chair.svg";
import { ReactComponent as InRoom } from "../../assets/residentstates/in-room.svg";
import { ReactComponent as MultipleOnInBed } from "../../assets/residentstates/in-bed-and-visitor.svg";
import { ReactComponent as MultipleOnInChair } from "../../assets/residentstates/in-chair-and-visitor.svg";
import { ReactComponent as OutOfRoom } from "../../assets/residentstates/out-of-room.svg";
import { Box, SvgIcon, TableCell, Tooltip } from "@mui/material";

const STATES = {
    IN_BATHROOM: {
        icon: InBathroom,
        color: "#4ca7a8",
        title: "In bathroom",
        description: "The resident is triggering sensors in the bathroom." },
    IN_BED: {
        icon: InBed,
        color: "#001f53",
        title: "In bed",
        description: "The bed has been occupied for over a minute." },
    IN_CHAIR: {
        icon: InChair,
        color: "#527a7a",
        title: "In chair",
        description: "The chair sensor has been occupied for over a minute." },
    IN_ROOM: {
        icon: InRoom,
        color: "#387e7f",
        title: "In room",
        description: "The resident is walking or sitting in the apartment." },
    MULTIPLE_ONE_IN_BED: {
        icon: MultipleOnInBed,
        color: "#8aad53",
        title: "In bed (visitor present)",
        description: "The resident is in bed, while another person has entered the apartment and triggers other sensors." },
    MULTIPLE_ONE_IN_CHAIR: {
        icon: MultipleOnInChair,
        color: "#8aad53",
        title: "In chair (visitor present)",
        description: "The resident is sitting in a chair, while another person has entered the apartment and triggers other sensors." },
    OUT_OF_ROOM: {
        icon: OutOfRoom,
        color: "#6ea51b",
        title: "Out of room",
        description: "The resident went to the door and no movement was seen for a while in the apartment. The algorithm concluded that the resident must have left the apartment." },
    DEGRADED: {
        icon: Default,
        color: "#afafaf",
        title: "Degraded",
        description: "No accurate resident state can be calculated due to a problem with the sensor set. The connection with the sensors and gateway/RF interface needs to be checked and restored." },
    UNDEFINED: {
        icon: Default,
        color: "#afafaf",
        title: "Undefined",
        description: "The algorithms have been reset or the sensors have not sent enough events to determine the state yet." },
    BED_UNPLUGGED: {
        icon: Default,
        color: "#afafaf",
        title: "Bed sensor unplugged",
        description: "This can happen when the cord between the sensor board and the actual bed sensor is unplugged or severed. Please check the sensor's cords." }
}

export default function ResidentState({ value }) {

    const state = STATES[value] || { icon: Default, color: "#afafaf", title: value, description: value };

    return (
        <Tooltip title={ state.description }>
            <TableCell sx={{ padding: "0.5em" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "1em" }}>
                    <SvgIcon sx={{ backgroundColor: state.color, color: "#ffffff", padding: "0.3em" }} component={ state.icon } inheritViewBox />
                    <span>{ state.title }</span>
                </Box>
            </TableCell>
        </Tooltip>
    );

}
