import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Autocomplete, Button, CircularProgress, Stack, TextField } from "@mui/material";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../../APIHelper";
import ImportReport from "./ImportReport";

export default function DevicesImportForm() {

    const auth = useAuth();
    const initOrganization = useSelector(state => state.organization);
    const [ organization, setOrganization ] = useState(initOrganization);
    const [ organizations, setOrganizations ] = useState([]);
    const [ sector, setSector ] = useState({id: "", name: ""});
    const [ sectors, setSectors ] = useState([]);
    const [ fileInput, setFileInput ] = useState("");
    const [ selectedFile, setSelectedFile ] = useState("");
    const [ devices, setDevices ] = useState([]);
    const [ errors, setErrors ] = useState([]);
    const [ busyLoading, setBusyLoading ] = useState(false);

    const selectOrganization = (organization) => {
        if (organization) {
            setOrganization(organization);
        } else {
            setOrganization({id: "", description: ""});
        }
        setSector({id: "", name: ""});
        setSectors([]);
    };

    const clearFileSelection = () => {
        setFileInput("");
        setSelectedFile("");
        setDevices([]);
        setErrors([]);
    };

    const onFileChange = (event) => {
        setBusyLoading(true);
        setDevices([]);
        setErrors([]);
        const token = auth.user?.access_token;
        const file = event.target.files[0];

        file.text().then((content) => {
            fetch(window.REACT_APP_API_URL + "/v3alpha/hardware/sets/import?organizationId=" + organization.id + "&sectorId=" + sector.id, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "text/csv"
                },
                body: content
            })
            .then((response) => {
                setBusyLoading(false);
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Could not process import response");
                }
            })
            .then((json) => {
                setSelectedFile(file.name);
                setDevices(json.devices.sort((d1, d2) => {
                    const name1 = d1.hardwareSet ? d1.hardwareSet.name : "";
                    const name2 = d2.hardwareSet ? d2.hardwareSet.name : "";
                    return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
                }));
                setErrors(json.errors);
            });
        });
    };

    useEffect(() => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/organizations",
            setItems: setOrganizations
        });
        if (organization || organization.id) {
            getItems({
                token: token,
                path: "/v3/sectors?organizationId=" + organization.id,
                setItems: setSectors
            });
        }

    }, [organization, auth]);

    return (
        <div>
            <Stack spacing={2} mt={3} mb={3}>
                <Autocomplete
                    id="organizationac"
                    options={organizations}
                    disableClearable="true"
                    openOnFocus="true"
                    getOptionLabel={(option) => option.description}
                    value={organization}
                    onChange={(event, value) => selectOrganization(value)}
                    renderInput={(params) => (
                        <TextField {...params} id="organization" label="Organization" variant="outlined" />
                    )}
                />
                <Autocomplete
                    id="sectorac"
                    options={sectors}
                    disableClearable="true"
                    openOnFocus="true"
                    getOptionLabel={(option) => option.name}
                    value={sector}
                    onChange={(event, value) => value ? setSector(value) : setSector({id: "", name: ""})}
                    renderInput={(params) => (
                        <TextField {...params} id="sector" label="Sector" variant="outlined" />
                    )}
                />

                <Button variant="contained" component="label" disabled={!organization.id || !sector.id} onClick={clearFileSelection}>
                    Select CSV file
                    <input value={fileInput} hidden accept="text/csv" type="file" onChange={onFileChange}/>
                </Button>
            </Stack>

            { busyLoading && <CircularProgress /> }
            { selectedFile && <ImportReport filename={selectedFile} devices={devices} errors={errors} /> }
        </div>
    );
}