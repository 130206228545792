import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField} from '@mui/material';
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

export default function EditWanderMappingDialog({isOpen, closeDialog, mapping, handleUpdate}) {

    const { register, handleSubmit, reset, getValues } = useForm({ defaultValues: mapping });
    const [filled, setFilled] = useState(true);

    useEffect(() => {
        reset({
            defaultValues: mapping
        })
    }, [mapping, reset])

    function handleFilledChange(){
        if ((getValues("label") && getValues("groupId")) && getValues("wanderLevel")){
            setFilled(true);
        }
        else {
            setFilled(false)
        }
    }

    return (
        <form display="flex" flexDirection="row" onSubmit={handleSubmit((newmapping) => handleUpdate(newmapping))} id={mapping.uuid}>
            <Dialog open={isOpen} >
                <DialogTitle sx={{ display: "flex", alignItems: "center" }} id="add-mapping-dialog-title">
                   Edit an existing mapping
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Make sure the Group ID is still correct!
                </DialogContentText>
                    <DialogContentText>
                    <FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
                        <TextField variant="standard" defaultValue={mapping.groupId} label="GroupId"
                                   {...register(
                                       "groupId",{
                                       onChange: (e) => {handleFilledChange()}
                                   })
                                   }/>
                        <TextField variant="standard" sx={{ display: 'none' }} defaultValue={mapping.uuid} {...register("uuid")} />
                    </FormControl>
                        <FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
                            <TextField variant="standard" defaultValue={mapping.wanderLevel} label="WanderLevel"
                                       {...register(
                                           "wanderLevel",{
                                               onChange: (e) => {handleFilledChange()}
                                           })
                                       }/>
                        </FormControl>
                    <FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
                        <TextField variant="standard" defaultValue={mapping.label} label="Label"
                                   {...register(
                                       "label",{
                                        onChange: (e) => {handleFilledChange()}
                                       })
                                   }/>
                    </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!filled} form={mapping.uuid}>Save</Button>
                    <Button onClick={closeDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </form>

        );

}
