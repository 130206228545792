import React from "react";

import ItemAdder from "../../molecules/ItemAdder";

export default function AlarmSubFlowAddition({ alarmFlow, alarmSubflows, addAlarmSubFlow, sectors }) {

	const availableSectors = sectors.filter(s => !alarmSubflows.find(sf => sf.sectorId === s.id));
	const lastAlarmSubflow = alarmSubflows.slice(-1)[0];
	const insertAfterSubFlowId = lastAlarmSubflow ? lastAlarmSubflow.id : null;

	const addItem = (sector) => addAlarmSubFlow({
		alarmFlowId: alarmFlow.id,
		sectorId: sector.id,
		insertAfterSubFlowId: insertAfterSubFlowId
	});

	return <ItemAdder title="Sector" items={availableSectors} addItem={addItem} />;

}
