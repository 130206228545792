export const retrieveShifts = (token, organization) => {
    return fetch(window.REACT_APP_API_URL + "/v3alpha/shifts" + (organization.id ? "?organizationId=" + organization.id : ""), {
        headers: {
            "Authorization": "Bearer " + token
        }
    });
}

export const getShiftName = (shiftId, shifts) => {
    const shift = shifts.find((s) => s.id === shiftId);
    if (shift) {
      return shift.name;
    } else {
      return "UNKNOWN";
    }
}