import React from "react";

import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function FilterMenu({ children }) {

    const theme = useTheme();

    return(
        <Stack spacing={2} sx={{ width: "240px", padding: "1em", backgroundColor: children ? theme.palette.background.secondary : "none" }}>
            {children}
        </Stack>
    );
}