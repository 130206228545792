import { Box, Card, CardContent, CircularProgress, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import Detail from '../../atoms/Detail';

import { useAuth } from "react-oidc-context";
import { useNavigate } from 'react-router-dom';
import { getItem } from "../../../APIHelper";
import { LoadingState } from "../../atoms/LoadingState";

export default function GatewayOverview({ gateway }) {
  const auth = useAuth();
  const navigate = useNavigate();

  const [personLoadingState, setPersonLoadingState] = useState(
    LoadingState.IDLE
  );
  const [person, setPerson] = useState(null);

  const sharedProperties = {
    display: "inline-block",
    verticalAlign: "middle"
  };

  const getPerson = () => {
    const token = auth.user?.access_token;
    getItem({
      token: token,
      path: "/v3alpha/persons/" + gateway.residentIds[0],
      setLoadingState: setPersonLoadingState,
      setItem: (person) => {
        setPerson(person)
      },
    });
  };

  useEffect(getPerson, [auth, gateway.residentIds]);

  const idle = personLoadingState === LoadingState.IDLE;
  const loading = personLoadingState === LoadingState.LOADING;
  const showPerson = personLoadingState === LoadingState.LOADED && person;
  const noneFound = personLoadingState === LoadingState.LOADED && !person;
  const error = personLoadingState === LoadingState.ERROR;

  return (
    <Card>
      <CardContent>
        <Detail title="Gateway" description={gateway.gatewayId} />
        <Detail title="Realm" description={gateway.realm} />
        <Detail title="Last Activity" description={gateway.lastActivity} />
        <Detail title="Status" description={gateway.state} />
        <Detail title="Reachability" description={gateway.reachability} />
        {(idle || loading) &&
          <CircularProgress />
        }
        {showPerson &&
          <Typography variant="body2" sx={{ margin: "0.5em" }}>
            <Box sx={{ ...sharedProperties, width: "10em", overflow: "hidden", fontWeight: "bold" }}>Resident name</Box>
            <Box sx={sharedProperties}><Link href="#" onClick={() => navigate(`/residents/details/${person.id}`)}>{person.fullName}</Link></Box>
          </Typography>
        }
        {(noneFound || error) &&
          <Detail title="Resident name" description="No resident found" />
        }
      </CardContent>
    </Card>
  );
}