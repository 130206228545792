import React, { useState } from "react";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export default function SelectableItemList({ items, onSelect }) {

    const [selectedId, setSelectedId] = useState(null);
    const select = (item) => {
        setSelectedId(item.id);
        onSelect(item);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <List component="nav">
            {items.map((item) => (
                <ListItem key={item.id} sx={{ padding: "0px", width: "100%" }}>
                    <ListItemButton selected={selectedId === item.id} onClick={() => select(item)}>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}
