import { LoadingState } from "./components/atoms/LoadingState";

const handleResponse = (response, onSuccess) => {
    if (response.ok) {
        onSuccess();
        return { isOk: true };
    } else {
        return response.json();
    }
}

const handleJson = (json) => {
    if (!json.isOk) {
        throw new Error(json.detail);
    }
}

const handleError = (error, onFailure) => {
    console.error(error);
    onFailure(error.message);
}

export function getItems({ token, path, signal = null, setLoadingState = () => { }, setItems }) {
    setLoadingState(LoadingState.LOADING);
    fetch(window.REACT_APP_API_URL + path, {
        signal,
        headers: { "Authorization": "Bearer " + token }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(`Failed to retrieve items from '${path}'.`);
        }
    }).then((json) => {
        setItems(json.items || json);
        setLoadingState(LoadingState.LOADED);
    }).catch((error) => {
        setLoadingState(LoadingState.ERROR);
        console.error(error);
    });
}

export function getItem({ token, path, signal = null, setLoadingState = () => { }, setItem, }) {
    setLoadingState(LoadingState.LOADING);
    fetch(window.REACT_APP_API_URL + path, {
        signal,
        headers: { Authorization: "Bearer " + token },
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Failed to retrieve item from '${path}'.`);
            }
        })
        .then((json) => {
            setItem(json);
            setLoadingState(LoadingState.LOADED);
        })
        .catch((error) => {
            setLoadingState(LoadingState.ERROR);
            console.error(error);
        });
}

export function createItem({ token, path, item, onSuccess, onFailure }) {
    fetch(window.REACT_APP_API_URL + path, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(item)
    })
        .then((response) => handleResponse(response, onSuccess))
        .then(handleJson)
        .catch((error) => handleError(error, onFailure));
}

export function createPlainTextItem({ token, path, item, onSuccess, onFailure }) {
    fetch(window.REACT_APP_API_URL + path, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "text/plain"
        },
        body: item
    })
        .then((response) => handleResponse(response, onSuccess))
        .then(handleJson)
        .catch((error) => handleError(error, onFailure));
}

export function updateItem({ token, path, item, onSuccess, onFailure }) {
    fetch(window.REACT_APP_API_URL + path, {
        method: "PATCH",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/merge-patch+json"
        },
        body: JSON.stringify(item)
    })
        .then((response) => handleResponse(response, onSuccess))
        .then(handleJson)
        .catch((error) => handleError(error, onFailure));
}

export function putItem({ token, path, item, onSuccess, onFailure }) {
    fetch(window.REACT_APP_API_URL + path, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/merge-patch+json"
        },
        body: JSON.stringify(item)
    })
        .then((response) => handleResponse(response, onSuccess))
        .then(handleJson)
        .catch((error) => handleError(error, onFailure));
}

export function deleteItem({ token, path, onSuccess, onFailure }) {
    fetch(window.REACT_APP_API_URL + path, {
        method: "DELETE",
        headers: { "Authorization": "Bearer " + token }
    })
        .then((response) => handleResponse(response, onSuccess))
        .then(handleJson)
        .catch((error) => handleError(error, onFailure));
}

const APIHelper = {
    getItems: getItems,
    getItem: getItem,
    createItem: createItem,
    updateItem: updateItem,
    deleteItem: deleteItem,
};
export default APIHelper;
