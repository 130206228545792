import { Add } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { createItem, deleteItem, getItems, updateItem } from "../../../APIHelper";
import { SET_FEEDBACK } from "../../../actions";
import ActionIcon from '../../atoms/ActionIcon';
import { LoadingState } from "../../atoms/LoadingState";
import SensaraAlert from "../../atoms/SensaraAlert";
import AddDeviceDialog from '../../molecules/devices/AddDeviceDialog';
import ConfirmationDialog from '../../molecules/ConfirmationDialog';
import DeviceCard from '../../molecules/devices/DeviceCard';
import DeviceDetailsDialog from '../../molecules/devices/DeviceDetailsDialog';
import EditDeviceDialog from '../../molecules/devices/EditDeviceDialog';

export default function DevicesDetails({ set }) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deviceToUnlink, setDeviceToUnlink] = useState({});
  const [unlinkDialogOpen, setUnlinkOpen] = useState(false);
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [editDialogOpen, setEditOpen] = useState(false);
  const [deviceDetailsToShow, setDeviceDetailsToShow] = useState({});
  const [detailsDialogOpen, setDetailsOpen] = useState(false);
  const [devices, setDevices] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [extension, setExtension] = useState({});
  const [organizationServer, setOrganizationServer] = useState({});
  const [unlinkMessage, setUnlinkMessage] = useState(`Are you sure you want to unlink this device?`);
  const [devicesLoadingState, setDevicesLoadingState] = useState(LoadingState.IDLE);
  const [orgServer, setOrgServer] = useState({});
  const [hasVoip, setHasVoip] = useState(false);
  // eslint-disable-next-line
  const [mappingLoadingSite, setMappingLoadingState] = useState(LoadingState.IDLE);

  useEffect(() => {
    const token = auth.user?.access_token;
    if (set.type === "set") {
      getItems({
        token: token,
        path: "/v3alpha/hardware/devices?hardwareSetId=" + set.id,
        setLoadingState: setDevicesLoadingState,
        setItems: setDevices,
      });
      getItems({
        token: token,
        path: "/v3alpha/organizations/" + set.organizationId + "/voip-integrations",
        setLoadingState: setDevicesLoadingState,
        setItems: setOrgServer,
      });
      getServer();
    } else {
      setDevices(set.devices);
      setDevicesLoadingState(LoadingState.LOADED);
    }
    try {
      getMappings(set);
    }
    catch (Exception){
    }
    // eslint-disable-next-line
  }, [auth, set]);

  useEffect(() => {
    if (orgServer.voipServerId != null){
      setHasVoip(true);
    }
    else{
      setHasVoip(false)
    }
  }, [orgServer]);

  const getMappings = (set) => {
    const token = auth.user?.access_token;
    getItems({
      token: token,
      path: "/v3alpha/hardware/wander-areas?organizationId=" + set.organizationId,
      setLoadingState: setMappingLoadingState,
      setItems: setMappings
    });
  }

  const getServer = () => {
    const token = auth.user?.access_token;
    getItems({
      token: token,
      path: "/v3alpha/voip-servers/organization/" + set.organizationId  ,
      setItems: setOrganizationServer,
    });
  }

  const getExtension = (device) => {
    if(device.realm === 'AKUVOX'){
      const token = auth.user?.access_token;
      getItems({
        token: token,
        path: "/v3alpha/device/"+ device.deviceId + "/extension" ,
        setLoadingState: setDevicesLoadingState,
        setItems: setExtension
      });
    }
  }

  const handleClickUnlinkDialogOpen = (device) => {
    setDeviceToUnlink(device);
    changeUnlinkMessage(device);
    setUnlinkOpen(true);
  };

  const handleUnlinkDialogClose = () => {
    setDeviceToUnlink({});
    setUnlinkOpen(false);
  };

  const handleEditOpen = (device) => {
    setDeviceToEdit(device);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setDeviceToEdit({});
    setEditOpen(false);
  };

  const handleDetailsOpen = (device) => {
    getExtension(device);
    setDeviceDetailsToShow(device);
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDeviceDetailsToShow({});
    setDetailsOpen(false);
  };

  const getDevices = () => {
    const token = auth.user?.access_token;
    if (set.type === "set") {
      getItems({
        token: token,
        path: "/v3alpha/hardware/devices?hardwareSetId=" + set.id,
        setLoadingState: setDevicesLoadingState,
        setItems: setDevices,
      });
    } else {
      getItems({
        token: token,
        path: "/v3alpha/hardware/gateways/" + set.gatewayId,
        setLoadingState: setDevicesLoadingState,
        setItems: (items) =>  {
          setDevices(items.devices);
        },
      });
    }
  }

  const setFeedback = (severity, message) => {
    dispatch({
      type: SET_FEEDBACK,
      feedback: { feedBackType: severity, feedBackMessage: message }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const postDevice = (device) => {
    if (device.realm === "KADEX"){
      postKadexDevice(device);
    }
    else if(device.realm === "AKUVOX"){
      postAkuvoxDevice(device)
    }
  }

  const BLE_ENABLED = "BLE";

  function postAkuvoxDevice(device){
    const token = auth.user?.access_token;
    const post = {
      type: "NurseCallDto",
      hardwareSetId: set.id,
      uuid: device.deviceId,
      organizationId: set.organizationId,
      friendlyName: device.friendlyName,
      productType: "AKUVOX"

    }
    updateItem({
      token: token,
      path: "/v3alpha/hardware/devices/voip/upsert/" + device.deviceId,
      item: post,
      setLoadingState: setDevicesLoadingState,
      onSuccess: () => {
        handleClose();
        setFeedback("info", `Device linked to hardware set`);
        getDevices();
      },
      onFailure: (message) => setFeedback("error", "Error while linking device to hardware set: " + message)
    })
  }

  function postKadexDevice(device){
    const token = auth.user?.access_token;
    const post = {
      deviceId: device.deviceId,
      realm: device.realm
    }
    createItem({
      token: token,
      path: "/v3alpha/hardware/sets/" + set.id + "/devices",
      item: post,
      onSuccess: () => {
        setFeedback("info", `Device linked to hardware set`);
        configureDevice(device);
      },
      onFailure: (message) => setFeedback("error", "Error while linking device to hardware set: " + message)
    })
    handleClose();
  }

  const changeUnlinkMessage = (device) =>{
    if (device.realm === "AKUVOX"){
      if (!device.label){
        setUnlinkMessage(`Are you sure you want to unlink this device?`);
      } else {
        setUnlinkMessage(`Are you sure you want to unlink device with label: ${device.label}?`);
      }
    } else {
        setUnlinkMessage(`Are you sure you want to unlink device with ID: ${device.deviceId}, location: ${device.location} and label: ${device.label}?`);
    }
  }

  const postMapping = (device) => {
    const post = {
      uuid: device.mappingUuid
    }
    const token = auth.user?.access_token;
    createItem({
      token: token,
      path: "/v3alpha/hardware/devices/" + device.deviceId + "/wander-areas",
      item: post,
      onSuccess: () => {
        setFeedback("info", `Device configured properly`);
      },
      onFailure: (message) => setFeedback("error", "Error while changing mapping: " + message)
    })
    handleClose();
  }

  const configureDevice = (device) => {
    const token = auth.user?.access_token;
    const patch = {
      type: device.type,
      label: device.location.label,
      location: device.location.value
    }
    updateItem({
      token: token,
      path: "/v3alpha/hardware/devices/" + device.deviceId,
      item: patch,
      onSuccess: () => {
        setFeedback("info", `Device configured properly`)
        getDevices();
      },
      onFailure: (message) => setFeedback("error", "Error while configuring device for hardware set: " + message)
    });
  };

  const patchKadexDevice = (device, ble) =>{
    const token = auth.user?.access_token;
    const patch = {
      type: device.type,
      label: device.label,
      profile:  ble === true ? BLE_ENABLED : null,
      location: device.location
    }
    updateItem({
      token: token,
      path: "/v3alpha/hardware/devices/" + device.deviceId,
      item: patch,
      onSuccess: () => {
        setFeedback("info", `Device configured properly`)
        getDevices();

        setEditOpen(false);
      },
      onFailure: (message) => setFeedback("error", "Error while configuring device for hardware set: " + message)
    });
    postMapping(device);
  };


  const patchAkuvoxDevice = (device)=> {
    const token = auth.user?.access_token;
    const patch = {
      type: "NurseCallDto",
      friendlyName : device.friendlyName,
      userName: device.userName,
      password: device.password,
      sipUserId : device.sipUserId
    }
    updateItem({
      token: token,
      path: "/v3alpha/hardware/devices/voip/" + device.deviceId,
      item: patch,
      onSuccess: () => {
        setFeedback("info", `Device configured properly`)
        getDevices();
        setEditOpen(false);
      },
      onFailure: (message) => setFeedback("error", "Error while configuring device for hardware set: " + message)
    });
  }

  const patchDevice = (device, ble) =>{
    if (device.realm === "AKUVOX"){
      patchAkuvoxDevice(device);
    }
    else if(device.realm !=="AKUVOX"){
      patchKadexDevice(device, ble);
    }
  }

  function unlinkKadexDevice(){
    const token = auth.user?.access_token;
    deleteItem({
      token: token,
      path: "/v3alpha/hardware/sets/" + set.id + "/devices/" + deviceToUnlink.deviceId,
      onSuccess: () => {
        setFeedback("info", "Device " + deviceToUnlink.deviceId + " is unlinked");
        getDevices();
      },
      onFailure: (error) => {
        setFeedback("error", "Error while unlinking device: " + error);
      }
    });
  }

  function unlinkVoipDevice(){
    const token = auth.user?.access_token;
    updateItem({
      token: token,
      path: "/v3alpha/hardware/devices/voip/unlink/" + deviceToUnlink.deviceId,
      onSuccess: () => {
        setFeedback("info", "Device " + deviceToUnlink.deviceId + " is unlinked");
        getDevices();
      },
      onFailure: (error) => {
        setFeedback("error", "Error while unlinking device: " + error);
      }
    });
  }

  function unlinkDevice() {
    console.log(deviceToUnlink.realm);
    if (deviceToUnlink.realm !== "AKUVOX"){
      unlinkKadexDevice()
    }
    else {
      unlinkVoipDevice()
    }
  }

  const idle = devicesLoadingState === LoadingState.IDLE;
  const loading = devicesLoadingState === LoadingState.LOADING;
  const showDevices = devicesLoadingState === LoadingState.LOADED && devices;
  const noneFound = devicesLoadingState === LoadingState.LOADED && !devices;
  const error = devicesLoadingState === LoadingState.ERROR;

  return (
    <Box>
      {(idle || loading) && <CircularProgress />}
      {noneFound && (
        <SensaraAlert severity="info" message="Hardware set does not exist" />
      )}
      {error && (
        <SensaraAlert severity="error" message="Hardware set could not be loaded." />
      )}
      <Toolbar>
        <Typography variant="h4" noWrap sx={{ flexGrow: 1 }}>Devices
        </Typography>
        <Box>
          {set.type === "set" && <ActionIcon action={() => handleClickOpen()} icon={<Add />} label="Link a device" />}
        </Box>
      </Toolbar>
      {showDevices && (
        <Grid container mt={1} spacing={2}>
          {devices.map((device) => (
            <Grid item xs={2} key={device.deviceId}>
              <DeviceCard
                device={device}
                handleUnlink={() => handleClickUnlinkDialogOpen(device)}
                handleDetails={() => handleDetailsOpen(device)}
                handleEdit={() => handleEditOpen(device)} />
            </Grid>
          ))}
        </Grid>)}
      <AddDeviceDialog
        isOpen={open}
        closeDialog={() => handleClose()}
        postDevice={(device) => postDevice(device)}
        hasVoip={hasVoip}
      />
      <ConfirmationDialog
        title="Unlink device?"
        description={unlinkMessage}
        action={unlinkDevice}
        isOpen={unlinkDialogOpen}
        closeDialog={handleUnlinkDialogClose} />
      <DeviceDetailsDialog
        isOpen={detailsDialogOpen}
        closeDialog={() => handleDetailsClose()}
        extension={extension}
        organizationServer={organizationServer}
        device={deviceDetailsToShow} />
      <EditDeviceDialog
        isOpen={editDialogOpen}
        closeDialog={() => handleEditClose()}
        device={deviceToEdit}
        patchDevice={(device, ble) => patchDevice(device, ble)}
        mappings={mappings}/>
    </Box>
  );
}
