import React from "react";

import Page from "./Page";
import DeviceWanderAreaForm from "../organisms/devices/DeviceWanderAreaForm";

export default function DeviceWanderArea() {

    return (
        <Page title="Create Wander Area Mappings">
            <DeviceWanderAreaForm />
        </Page>
    );
}
