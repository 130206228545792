import React, {useState} from "react";
import { useForm } from "react-hook-form";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";


export default function AddSipIntegrationDialog({ isOpen, closeDialog, handleActivation, pbxServers }) {

	const { register, handleSubmit, getValues} = useForm();
	const [filled, setFilled] = useState(false);
	function handleFilledChange(){
		if (getValues("uuid")){
			setFilled(true);
		}
		else {
			setFilled(false)
		}
	}



	return (
		<form onSubmit={handleSubmit((pbxServer) => handleActivation(pbxServer))} id="mappingForm">
			<Dialog open={isOpen} >
				<DialogTitle sx={{ display: "flex", alignItems: "center" }} id="add-mapping-dialog-title">
					Activate sip functionalities
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<InputLabel>Pbx </InputLabel>
							<Select  {...register("uuid",{
								onChange: (e) => {handleFilledChange()}
							})}>
								{pbxServers.map((server) => (
									<MenuItem key={server.uuid} value={server.uuid}>{server.description}</MenuItem>
								))}
							</Select>
						</FormControl>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button type="submit"  disabled={!filled} form="mappingForm">Save</Button>
					<Button onClick={closeDialog}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
}
