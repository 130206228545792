import React, { useEffect, useState } from "react";

import { CircularProgress, Stack } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { getItem } from "../../../APIHelper";
import { useAuth } from "react-oidc-context";
import ActionIcon from "../../atoms/ActionIcon";
import { LoadingState } from "../../atoms/LoadingState";
import SensaraAlert from "../../atoms/SensaraAlert";
import GatewayDevicesOverview from "./GatewayDevicesOverview";

export default function GatewayPage() {
  const { gatewayId } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();

  const [gatewayLoadingState, setGatewayLoadingState] = useState(
    LoadingState.IDLE
  );
  const [gateway, setGateway] = useState(null);

  const getGateway = () => {
    const token = auth.user?.access_token;
    getItem({
      token: token,
      path: "/v3alpha/hardware/gateways/" + gatewayId,
      setLoadingState: setGatewayLoadingState,
      setItem: (gateway) => { 
        gateway.type = "gateway";
        setGateway(gateway) 
      },
    });
  };

  useEffect(getGateway, [gatewayId, auth]);

  const idle = gatewayLoadingState === LoadingState.IDLE;
  const loading = gatewayLoadingState === LoadingState.LOADING;
  const showGateway = gatewayLoadingState === LoadingState.LOADED && gateway;
  const noneFound = gatewayLoadingState === LoadingState.LOADED && !gateway;
  const error = gatewayLoadingState === LoadingState.ERROR;

  return (
    <Stack ml={2}>
      {(idle || loading) && <CircularProgress />}
      {showGateway && <GatewayDevicesOverview gateway={gateway} />}

      {noneFound && (
        <SensaraAlert severity="info" message="Gateway does not exist" />
      )}
      {error && (
        <SensaraAlert severity="error" message="Gateway could not be loaded." />
      )}

      <Stack spacing={2} mt={5} direction="row">
        <ActionIcon
          action={() => navigate("/devices/overview")}
          icon={<ArrowBackIcon />}
          label="Back"
        />
      </Stack>
    </Stack>
  );
}
