import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import FilterMenu from "./FilterMenu";

export default function BackMenu({ parentPath, parentName }) {

	const navigate = useNavigate();

	return (
		<FilterMenu>
			<Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate(parentPath)} >
				Back to {parentName}
			</Button>
		</FilterMenu>
	);
}