import React, { useEffect, useState } from "react";

import { Button, Box, CircularProgress, Divider, Typography } from "@mui/material";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import SensaraAlert from "../atoms/SensaraAlert";
import { LoadingState } from "../atoms/LoadingState";
import SectorFilterMenu from "../molecules/SectorFilterMenu";
import Page from "./Page";
import GatewayTable from "../organisms/devices/GatewayTable";
import HardwareSetTable from "../organisms/devices/HardwareSetTable";

export default function Devices() {

    const auth = useAuth();

    const [ selectedSector, setSelectedSector ] = useState("");

    const [ hardwareSets, setHardwareSets ] = useState([]);
    const [ hardwareSetsLoadingState, setHardwareSetsLoadingState ] = useState(LoadingState.IDLE);

    const [ gateways, setGateways ] = useState([]);
    const [ gatewaysLoadingState, setGatewaysLoadingState ] = useState(LoadingState.IDLE);

    const [gatewaysLimit, setGatewaysLimit] = useState(10)
    const [hardwareSetsLimit, setHardwareSetsLimit] = useState(10)

    const getDevicesForSector = (sector) => {
        setSelectedSector(sector);
        getGatewaysForSector(sector);
        getHardwareSetsForSector(sector);
    }

    const getGatewaysForSector = (sector, direction) => {
        const token = auth.user?.access_token;

        var pageable = "page=1&limit="+gatewaysLimit;
        if(direction === `next`) {
            pageable = gateways.metadata.next;
        } else if(direction === `previous`) {
            pageable = gateways.metadata.prev;
        }

        getItems({
            token: token,
            path: "/v3alpha/hardware/gateways/page?sectorId=" + sector.id + "&" + pageable,
            setLoadingState: setGatewaysLoadingState,
            setItems: setGateways
        })
    }

    const getHardwareSetsForSector = (sector, direction) => {
        const token = auth.user?.access_token;

        var pageable = "page=1&limit="+hardwareSetsLimit;
        if(direction === `next`) {
            pageable = hardwareSets.metadata.next;
        } else if(direction === `previous`) {
            pageable = hardwareSets.metadata.prev;
        }

        getItems({
            token: token,
            path: "/v3alpha/hardware/sets/page?sectorId=" + sector.id + "&" + pageable,
            setLoadingState: setHardwareSetsLoadingState,
            setItems: setHardwareSets
        })
    }

    useEffect(() => {
        if(selectedSector) {
            getGatewaysForSector(selectedSector);
        }
        // eslint-disable-next-line
      }, [gatewaysLimit])

    useEffect(() => {
        if(selectedSector) {
            getHardwareSetsForSector(selectedSector);
        }
        // eslint-disable-next-line
    }, [hardwareSetsLimit])

    const idle = gatewaysLoadingState === LoadingState.IDLE && hardwareSetsLoadingState === LoadingState.IDLE;
    const loading = ((gatewaysLoadingState === LoadingState.LOADING && gateways===null)
        || (hardwareSetsLoadingState === LoadingState.LOADING && hardwareSets===null));
    const showGateways = gatewaysLoadingState === LoadingState.LOADED && gateways;
    const showHardwareSets = hardwareSetsLoadingState === LoadingState.LOADED && hardwareSets;
    const noneFound = hardwareSetsLoadingState === LoadingState.LOADED && hardwareSets===null
        && gatewaysLoadingState === LoadingState.LOADED && gateways===null;

    return (
        <Page title={"Devices" + (selectedSector ? " of " + selectedSector.name : "")}
            filters={<SectorFilterMenu onSelectSector={getDevicesForSector} />}>
            { idle && <SensaraAlert severity="info" message="Please select a sector from the list." /> }
            { loading && <CircularProgress /> }
            { showGateways && <GatewayTable gateways={gateways.results.sort((s1, s2) => s1.state.localeCompare(s2.state))} />}
            { showGateways && showHardwareSets && <Divider sx={{ mt: 6 , mb: 6 , ml: 0, mr: 0}} variant="middle" /> }
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                { showGateways && <>
                    <Typography variant="caption">Rows per page</Typography>
                    <select value={gatewaysLimit}  onChange={(e=> setGatewaysLimit(e.target.value))}  style={{width:'auto',height:'20px', marginLeft:'1em', marginRight:'12px'}}>  
                        <option     value="5">5</option>
                        <option     value="10">10</option>
                        <option     value="15">15</option>
                        <option     value="20">20</option>
                        <option     value="30">30</option>
                    </select>
                    <Button sx={{width:'35'}} disabled={gateways.metadata.prev === null} onClick={() => getGatewaysForSector(selectedSector, "previous")}>&lt;</Button>
                    <Button sx={{width:'35'}} disabled={gateways.metadata.next === null} onClick={() => getGatewaysForSector(selectedSector, "next")}>&gt;</Button>
                </>}
            </Box>

            { showHardwareSets && <HardwareSetTable hardwaresets={hardwareSets.results.sort((s1, s2) => s1.name.localeCompare(s2.name)).sort((s1, s2) => s1.state.localeCompare(s2.state))} />}
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                { showHardwareSets && <>
                    <Typography variant="caption">Rows per page</Typography>
                    <select value={hardwareSetsLimit}  onChange={(e=> setHardwareSetsLimit(e.target.value))}  style={{width:'auto',height:'20px', marginLeft:'1em', marginRight:'12px'}}>  
                        <option     value="5">5</option>
                        <option     value="10">10</option>
                        <option     value="15">15</option>
                        <option     value="20">20</option>
                        <option     value="30">30</option>
                    </select>
                    <Button sx={{width:'35'}} disabled={hardwareSets.metadata.prev === null} onClick={() => getHardwareSetsForSector(selectedSector, "previous")}>&lt;</Button>
                    <Button sx={{width:'35'}} disabled={hardwareSets.metadata.next === null} onClick={() => getHardwareSetsForSector(selectedSector, "next")}>&gt;</Button>
                </>}
            </Box>

            { noneFound && <SensaraAlert severity="info" message="Currently, no devices are linked to this sector." /> }
            { hardwareSets === LoadingState.ERROR && <SensaraAlert severity="error" message="Hardware sets for this sector could not be loaded." /> }
            { gatewaysLoadingState === LoadingState.ERROR && <SensaraAlert severity="error" message="Gateways for this sector could not be loaded." /> }
        </Page>
    );
}
