import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	InputLabel, LinearProgress,
	MenuItem,
	Select,
	TextField
} from "@mui/material";


export default function AddDeviceDialog({ isOpen, closeDialog, postDevice, hasVoip }) {
	const { register, handleSubmit } = useForm();

	const[ visible, setVisible ] = useState(true);
	const[realm, setRealm] = useState('KADEX');
	const[loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(false);
	},[isOpen])
	const doSubmit = async (device) => {
		setLoading(true);
		await postDevice(device);
	};

	const handleChange = (event) => {
		setRealm(event.target.value);
		if (event.target.value === "AKUVOX") {
			setVisible(false);
		} else {
			setVisible(true);
		}
	  };

	const types = [
		{ value: "MV", label: "Motion sensor" },
		{ value: "BS", label: "Emfit QS Bed sensor" },
		{ value: "CS", label: "Chair sensor" },
		{ value: "DR", label: "Open/close sensor or Pull cord" },
		{ value: "AL", label: "Alarm button" },
		{ value: "PA", label: "Personal Alarm" }
	];

	const location = [
		{ value: "ALARM_BUTTON", label: "Alarm button" },
		{ value: "BED", label: "Bed" },
		{ value: "CHAIR", label: "Chair" },
		{ value: "EXTERNAL_DOOR", label: "External Door" },
		{ value: "HALL", label: "Hallway" },
		{ value: "HOBBY_ROOM", label: "Hobby room" },
		{ value: "KITCHEN", label: "Kitchen" },
		{ value: "LIVING_ROOM", label: "Living room" },
		{ value: "PULL_CORD", label: "Pull cord" },
		{ value: "TOILET", label: "Toilet" },
	];

	return (
		<form onSubmit={handleSubmit((device) => doSubmit(device))} id="deviceForm">
			<Dialog open={isOpen} >
				<DialogTitle sx={{ display: "flex", alignItems: "center" }} id="add-device-dialog-title">
					Link a device { realm }
				</DialogTitle>
				<DialogContent>
				<DialogContentText>
						Make sure the device is online before linking it.
					</DialogContentText>
					<DialogContentText>
						{ visible &&
							<FormControl variant="standard" sx={{ width: "75%", mt: 1, minWidth: 150}}>
								<InputLabel>Type</InputLabel>
								<Select defaultValue="" {...register("type")}>
									{types.map((type) => (
										<MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
									))}

								</Select>
							</FormControl>
						}
						{ visible &&
							<FormControl variant="standard" sx={{ width: "75%", mt: 1, minWidth: 150 }}>
								<InputLabel>Location</InputLabel>
								<Select defaultValue="" {...register("location")}>
									{location.map((type) => (
										<MenuItem key={type.value} value={type}>{type.label}</MenuItem>
									))}
								</Select>
							</FormControl>
						}
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<InputLabel>Realm</InputLabel>
							<Select value={realm}  {...register("realm", {onChange: (e) => {handleChange(e)}} ) }>
								<MenuItem value="KADEX">KADEX</MenuItem>
								{hasVoip && <MenuItem value="AKUVOX">AKUVOX</MenuItem>}
							</Select>
						</FormControl>
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<TextField variant="standard" label="Device ID" {...register("deviceId")} />
						</FormControl>
						{ !visible &&
							<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
								<TextField variant="standard" label="Friendly Name" {...register("friendlyName")} />
							</FormControl>
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button type="submit" form="deviceForm" disabled={loading} >Save</Button>
					<Button onClick={closeDialog}>Cancel</Button>
				</DialogActions>
				{loading && <LinearProgress/>}
			</Dialog>
		</form>
	);
}
