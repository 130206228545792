import React from "react";
import SignalWifi1BarIcon from "@mui/icons-material/SignalWifi1Bar";
import SignalWifi2BarIcon from "@mui/icons-material/SignalWifi2Bar";
import SignalWifi3BarIcon from "@mui/icons-material/SignalWifi3Bar";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import WarningIcon from "@mui/icons-material/Warning";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip } from "@mui/material";

export default function SignalStrengthIcon({rssi}) {

    if (rssi < -80) {
        return <Tooltip title={`Very bad; please improve the set-up (${rssi})`}><WarningIcon color="warning" /></Tooltip>;
    } else if (rssi < -70) {
        return <Tooltip title={`Bad; you might need to improve the set-up (${rssi})`}><SignalWifi1BarIcon color="warning" /></Tooltip>;
    } else if (rssi < -67) {
        return <Tooltip title={`Okay (${rssi})`}><SignalWifi2BarIcon color="success" /></Tooltip>;
    } else if (rssi < -50) { 
        return <Tooltip title={`Good (${rssi})`}><SignalWifi3BarIcon color="success" /></Tooltip>;
    } else if (rssi < 0) {
        return <Tooltip title={`Very good (${rssi})`}><SignalWifi4BarIcon color="success" /></Tooltip>;
    } else {
        return <Tooltip title={`Unknown; please check the set-up (${rssi})`}><HelpIcon color="error" /></Tooltip>;
    }

};