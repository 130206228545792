import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { formatTime, parseTime } from "../../../functions/temporalFunctions";
import ConfirmationDialog from "../../molecules/ConfirmationDialog";

export default function AlarmFlowForm({ initAlarmFlow, createAlarmFlow, updateAlarmFlow, deleteAlarmFlow }) {
    const id = initAlarmFlow ? initAlarmFlow.id : null;
    const organization = useSelector(state => state.organization);

    const [name, setName] = useState(initAlarmFlow ? initAlarmFlow.name : "");
    const [startDay, setStartDay] = useState(initAlarmFlow ? initAlarmFlow.startDay : null);
    const [startTime, setStartTime] = useState(initAlarmFlow ? initAlarmFlow.startTime : null);
    const [endDay, setEndDay] = useState(initAlarmFlow ? initAlarmFlow.endDay : null);
    const [endTime, setEndTime] = useState(initAlarmFlow ? initAlarmFlow.endTime : null);

    const create = "create";
    const edit = "edit";
    const formMode = initAlarmFlow && initAlarmFlow.id ? edit : create;

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const valid = name && organization.id && startDay && startTime && endDay && endTime;

    const currentAlarmFlow = {
        name: name,
        organizationId: organization.id,
        startDay: startDay,
        startTime: startTime,
        endDay: endDay,
        endTime: endTime
    };

    return (
        <Stack spacing={2}>
            <TextField id="name" label="Name" variant="outlined"
                value={name}
                required="true"
                inputProps={{ maxLength: 64 }}
                floatingLabelText="Name"
                fullWidth="true"
                onChange={event => setName(event.target.value)} />
            <Stack spacing={2} direction="row">
                <FormControl fullWidth="true" required="true">
                    <InputLabel id="startDay-selectlabel">Start Day</InputLabel>
                    <Select
                        labelId="startDay-selectlabel"
                        id="startDay"
                        value={startDay}
                        label="Start Day"
                        autoWidth="true"
                        required="true"
                        onChange={event => setStartDay(event.target.value)}>
                        <MenuItem value={"MONDAY"}>Monday</MenuItem>
                        <MenuItem value={"TUESDAY"}>Tuesday</MenuItem>
                        <MenuItem value={"WEDNESDAY"}>Wednesday</MenuItem>
                        <MenuItem value={"THURSDAY"}>Thursday</MenuItem>
                        <MenuItem value={"FRIDAY"}>Friday</MenuItem>
                        <MenuItem value={"SATURDAY"}>Saturday</MenuItem>
                        <MenuItem value={"SUNDAY"}>Sunday</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth="true" required="true">
                    <InputLabel id="endDay-selectlabel">End Day</InputLabel>
                    <Select
                        labelId="endDay-selectlabel"
                        id="endDay"
                        value={endDay}
                        label="End Day"
                        autoWidth="true"
                        required="true"
                        onChange={event => setEndDay(event.target.value)}>
                        <MenuItem value={"MONDAY"}>Monday</MenuItem>
                        <MenuItem value={"TUESDAY"}>Tuesday</MenuItem>
                        <MenuItem value={"WEDNESDAY"}>Wednesday</MenuItem>
                        <MenuItem value={"THURSDAY"}>Thursday</MenuItem>
                        <MenuItem value={"FRIDAY"}>Friday</MenuItem>
                        <MenuItem value={"SATURDAY"}>Saturday</MenuItem>
                        <MenuItem value={"SUNDAY"}>Sunday</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <Stack spacing={2} direction="row">
                <TimePicker
                    id="startTime"
                    label="Start Time"
                    value={parseTime(startTime)}
                    onChange={value => setStartTime(formatTime(value))}
                    renderInput={(params) => <TextField {...params} />}
                    ampm={false}
                />
                <TimePicker
                    id="endTime"
                    label="End Time"
                    value={parseTime(endTime)}
                    required="true"
                    onChange={value => setEndTime(formatTime(value))}
                    renderInput={(params) => <TextField {...params} />}
                    ampm={false}
                />
            </Stack>
            {
                formMode === create ?
                    <Stack spacing={2} direction="row">
                        <Button id="cancel" name="Cancel" component={RouterLink}
                            to="/alarms/flows/overview" variant="contained">Cancel</Button>
                        <Button id="create" name="Create" onClick={() => createAlarmFlow(currentAlarmFlow)}
                            disabled={!valid} variant="contained">Create</Button>
                    </Stack>
                    :
                    <Stack spacing={2} direction="row">
                        <Button id="cancel" name="Cancel" component={RouterLink} to="/alarms/flows/overview"
                            variant="contained">Cancel</Button>
                        <Button id="delete" name="Delete" onClick={() => setDeleteDialogOpen(true)}
                            variant="contained">Delete</Button>
                        <Button id="update" name="Update" onClick={() => updateAlarmFlow(id, currentAlarmFlow)}
                            disabled={!valid} variant="contained">Update</Button>
                        <ConfirmationDialog
                            title="Delete alarm flow?"
                            description={`Are you sure you want to delete alarm flow ${name}?`}
                            action={() => deleteAlarmFlow(id, name)}
                            isOpen={deleteDialogOpen}
                            closeDialog={() => setDeleteDialogOpen(false)} />
                    </Stack>
            }
        </Stack>
    );
}
