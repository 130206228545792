import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Delete, Edit, List } from "@mui/icons-material";
import { useAuth } from "react-oidc-context";

import { SET_FEEDBACK } from "../../../actions";
import ActionIcon from "../../atoms/ActionIcon";
import ConfirmationDialog from "../../molecules/ConfirmationDialog";
import TableHeader from "../../atoms/TableHeader";
import APIHelper from "../../../APIHelper";

export default function AlarmFlowsTable({ alarmFlows, setDeletedAlarmFlowId }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useAuth();
    const [removalAlarmFlow, setRemovalAlarmFlow] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const handleClickDeleteDialogOpen = (alarmFlow) => {
        setRemovalAlarmFlow(alarmFlow);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setRemovalAlarmFlow(null);
        setDeleteDialogOpen(false);
    };

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };

    async function deleteAlarmFlow() {
        const token = auth.user?.access_token;
        APIHelper.deleteItem({
            token: token,
            path: "/v3alpha/alarm-flows/" + removalAlarmFlow.id,
            onSuccess: () => {
                setFeedback("info", "Alarm flow " + removalAlarmFlow.name + " is deleted");
                setDeletedAlarmFlowId(removalAlarmFlow.id);
            },
            onFailure: (error) => {
                setFeedback("error", "Error while deleting the alarm flow: " + error);
            }
        });
    }

    return (
        <TableContainer>
            <Table aria-label="Alarm Flows">
                <TableHeader columns={["Name", "Actions"]} />
                <TableBody>
                    {alarmFlows.map((alarmFlow) => (
                        <TableRow
                            key={alarmFlow.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>{alarmFlow.name}</TableCell>
                            <TableCell>
                                <ActionIcon action={() => navigate("/alarms/flows/form", { state: alarmFlow })} icon={<Edit />} label="Edit" />
                                <ActionIcon action={() => navigate("/alarms/flows/subflows/overview", { state: alarmFlow })} icon={<List />} label="Manage Subflows" />
                                <ActionIcon action={() => handleClickDeleteDialogOpen(alarmFlow)} icon={<Delete />} label="Delete" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ConfirmationDialog
                title="Delete alarm flow?"
                description={`Are you sure you want to delete alarm flow ${removalAlarmFlow ? removalAlarmFlow.name : ""}?`}
                action={deleteAlarmFlow}
                isOpen={deleteDialogOpen}
                closeDialog={handleDeleteDialogClose} />
        </TableContainer>
    );
}