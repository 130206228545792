import React from "react";

import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OrganizationSelector from "../organisms/organization/OrganizationSelector";

export default function MainHeader({ drawerWidth }) {

    const theme = useTheme();

    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, height: "77px",backgroundColor: `${theme.palette.primary.main}` }}>
            <Toolbar sx={{ height: "77px" }}>
                <Typography variant="h4" noWrap sx={{ flexGrow: 1 }}>Connect</Typography>
                <Box sx={{ width: 200, backgroundColor: `${theme.palette.primary.main}`, color: "#fff"  }}>
                    <OrganizationSelector />
                </Box>
            </Toolbar>
        </AppBar>
    );

}