import React from "react";

import { Card, CardContent } from "@mui/material";

import Detail from "../../../atoms/Detail";

export default function ShiftDetailsPanel({ shift }) {

	return (
		<Card>
			<CardContent>
				<Detail title="Name" description={shift.name} />
				<Detail title="Description" description={shift.description} />
			</CardContent>
		</Card>
	);

}