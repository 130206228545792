import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { SET_FEEDBACK } from "../../actions";
import { getSectorName, retrieveSectors } from "../../functions/sectorFunctions";
import { useAuth } from "react-oidc-context";
import { LoadingState } from "../atoms/LoadingState";
import SensaraAlert from "../atoms/SensaraAlert";
import AlarmFlowView from "../organisms/alarmflows/AlarmFlowView";
import AlarmSubFlowAddition from "../organisms/alarmflows/AlarmSubFlowAddition";
import AlarmSubFlowsTable from "../organisms/alarmflows/AlarmSubFlowsTable";
import Page from "./Page";

export default function AlarmSubFlowListPanel() {
  const auth = useAuth();
  const organization = useSelector((state) => state.organization);
  const location = useLocation();
  const dispatch = useDispatch();

  const alarmFlow = location.state;

  const [alarmSubFlows, setAlarmSubFlows] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
  const [
    alarmFlowOverviewUpdateTrigger,
    setAlarmFlowOverviewUpdateTrigger,
  ] = useState(null);

  const [sectors, setSectors] = useState([]);
  const [sectorLoadingState, setSectorLoadingState] = useState(
    LoadingState.IDLE
  );

  const getSectors = React.useCallback(async () => {
    setSectorLoadingState(LoadingState.LOADING);
    const sectorsResponse = await retrieveSectors(auth.user?.access_token, organization);
    if (!sectorsResponse.ok) {
      setSectorLoadingState(LoadingState.ERROR);
      return [];
    }
    const json = await sectorsResponse.json();
    setSectors(json.items.filter((s) => s.name !== "ARCHIVE"));
    setSectorLoadingState(LoadingState.LOADED);
  }, [auth, organization]);

  useEffect(() => {
    getSectors();
  }, [auth, organization, getSectors]);

  const getAlarmSubFlows = React.useCallback(async () => {
    const token = auth.user?.access_token;
    const alarmSubFlowsResponse = await fetch(
      window.REACT_APP_API_URL +
      "/v3alpha/alarm-subflows?alarmFlowId=" +
      alarmFlow.id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (!alarmSubFlowsResponse.ok) {
      setLoadingState(LoadingState.ERROR);
    } else {
      const json = await alarmSubFlowsResponse.json();
      setAlarmSubFlows(json.items);
      setLoadingState(LoadingState.LOADED);
    }
  }, [alarmFlow.id, auth]);

  const loadAlarmSubFlows = React.useCallback(() => {
    if (alarmFlow.id) {
      setLoadingState(LoadingState.LOADING);
      getAlarmSubFlows();
    } else {
      setLoadingState(LoadingState.IDLE);
    }
  }, [getAlarmSubFlows, alarmFlow.id]);

  useEffect(loadAlarmSubFlows, [alarmFlow, alarmFlowOverviewUpdateTrigger, getAlarmSubFlows, loadAlarmSubFlows]);

  async function deleteAlarmSubFlow(id, sector) {
    const token = auth.user?.access_token;
    const alarmFlowResponse = await fetch(
      window.REACT_APP_API_URL + "/v3alpha/alarm-subflows/" + id,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (alarmFlowResponse.ok) {
      setAlarmFlowOverviewUpdateTrigger(Math.random());
      dispatch({
        type: SET_FEEDBACK,
        feedback: {
          feedBackType: "info",
          feedBackMessage:
            "Alarm subflow for sector " +
            getSectorName(sector, sectors) +
            " is deleted",
        },
      });
    } else {
      const json = await alarmFlowResponse.json();
      dispatch({
        type: SET_FEEDBACK,
        feedback: {
          feedBackType: "error",
          feedBackMessage:
            "Error while deleting the alarm subflow: " + json.detail,
        },
      });
    }
  }

  async function updateAlarmSubFlow(id, alarmSubFlowUpdate) {
    const token = auth.user?.access_token;
    const alarmSubFlowResponse = await fetch(
      window.REACT_APP_API_URL + "/v3alpha/alarm-subflows/" + id,
      {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/merge-patch+json",
        },
        body: JSON.stringify(alarmSubFlowUpdate),
      }
    );
    const json = await alarmSubFlowResponse.json();
    if (alarmSubFlowResponse.ok) {
      setAlarmFlowOverviewUpdateTrigger(Math.random());
    } else {
      dispatch({
        type: SET_FEEDBACK,
        feedback: {
          feedBackType: "error",
          feedBackMessage:
            "Error while updating the alarm subflow: " + json.detail,
        },
      });
    }
  }

  async function addAlarmSubFlow(alarmSubFlow) {
    const token = auth.user?.access_token;
    const alarmSubFlowResponse = await fetch(
      window.REACT_APP_API_URL + "/v3alpha/alarm-subflows",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(alarmSubFlow),
      }
    );
    const json = await alarmSubFlowResponse.json();
    if (alarmSubFlowResponse.ok) {
      setAlarmFlowOverviewUpdateTrigger(Math.random());
      dispatch({
        type: SET_FEEDBACK,
        feedback: {
          feedBackType: "info",
          feedBackMessage:
            "Alarm subflow for sector " +
            getSectorName(json.sectorId, sectors) +
            " is created",
        },
      });
    } else {
      dispatch({
        type: SET_FEEDBACK,
        feedback: {
          feedBackType: "error",
          feedBackMessage:
            "Error while creating the alarm subflow: " + json.detail,
        },
      });
    }
  }

  return (
    <Page variant="slim" title={alarmFlow.name}>
      <AlarmFlowView alarmFlow={alarmFlow} />
      {(loadingState === LoadingState.LOADING ||
        sectorLoadingState === LoadingState.LOADING) && <CircularProgress />}
      {loadingState === LoadingState.LOADED &&
        sectorLoadingState === LoadingState.LOADED && (
          <AlarmSubFlowsTable
            alarmFlow={alarmFlow}
            alarmSubFlows={alarmSubFlows}
            deleteAlarmSubFlow={deleteAlarmSubFlow}
            updateAlarmSubFlow={updateAlarmSubFlow}
            sectors={sectors}
          />
        )}
      {loadingState === LoadingState.LOADED &&
        sectorLoadingState === LoadingState.LOADED && (
          <AlarmSubFlowAddition
            alarmFlow={alarmFlow}
            alarmSubflows={alarmSubFlows}
            addAlarmSubFlow={addAlarmSubFlow}
            sectors={sectors}
          />
        )}
      {loadingState === LoadingState.ERROR && (
        <SensaraAlert
          severity="error"
          message="The alarm subflows could not be loaded."
        />
      )}
      {sectorLoadingState === LoadingState.ERROR && (
        <SensaraAlert
          severity="error"
          message="The sectors could not be loaded."
        />
      )}
    </Page>
  );
}