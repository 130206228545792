import React from "react"

import { AccessAlarmOutlined, HomeOutlined, PersonOutlineOutlined, SettingsOutlined, SettingsRemoteOutlined, BadgeOutlined } from "@mui/icons-material"
import { Box, Drawer, List, ListItemButton, ListItemText } from "@mui/material"

import { useAuth } from "react-oidc-context";
import MainHeader from "../atoms/MainHeader"
import MenuItem from "../atoms/MenuItem"
import Version from "../atoms/Version"
import SubMenu from "../molecules/SubMenu"
import { getUserRoles } from "../../functions/TokenFunctions"

export default function MainMenu({ drawerWidth }) {
    const auth = useAuth();
    const isAdmin = () => {
        const roles = getUserRoles(auth);
        return roles.includes("ADMIN") || roles.includes("SUPERADMIN");
    }

    return (
        <Box sx={{ display: "flex" }}>
            <MainHeader drawerWidth={drawerWidth} />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <img src="/logo.jpg" alt="Sensara logo" />
                <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }} component="nav">
                        <MenuItem icon={<HomeOutlined />} title="Home" link="/" />

                        <SubMenu icon={<SettingsRemoteOutlined />} title="Devices" rootPath="/devices">
                            <MenuItem title="Overview" link="/devices/overview" />
                            <MenuItem title="Technical Alarms" link="/devices/alarms/overview" />
                            {isAdmin() && <MenuItem title="Import" link="/devices/import" />}
                            {isAdmin() && <MenuItem title="Wander Area" link="/devices/wander" />}
                        </SubMenu>

                        <SubMenu icon={<AccessAlarmOutlined />} title="Alarms" rootPath="/alarms">
                            <MenuItem title="Overview" link="/alarms/overview" />
                            <MenuItem title="Shifts" link="/alarms/shifts/overview" rootPath="/alarms/shifts/" />
                            <MenuItem title="Flows" link="/alarms/flows/overview" rootPath="/alarms/flows/" />
                        </SubMenu>

                        <SubMenu icon={<PersonOutlineOutlined />} title="Residents" rootPath="/residents">
                            <MenuItem title="Overview" link="/residents/overview" />
                            <MenuItem title="State History" link="/residents/states/history" />
                            {isAdmin() && <MenuItem title="Import" link="/residents/import" />}
                        </SubMenu>

                        <SubMenu icon={<BadgeOutlined />} title="Users" rootPath="/users">
                            <MenuItem title="Overview" link="/users/overview" />
                        </SubMenu>

                        <SubMenu icon={<SettingsOutlined />} title="Settings">
                            <MenuItem title="Integrations" link="/settings/integrations" />
                            {/* <ListItemButton onClick={keycloak.accountManagement}>
                                <ListItemText primary="Account" />
                            </ListItemButton> */}
                            <ListItemButton onClick={auth.signoutRedirect}>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </SubMenu>
                    </List>
                    <Version />
                </Box>
            </Drawer>
        </Box>
    )
}
