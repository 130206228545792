import React from "react";

import { Box, Container, Typography } from "@mui/material";

import ImportedDevicesTable from "./ImportedDevicesTable";
import ImportErrorList from "./ImportErrorList";

export default function ImportReport({ filename, devices, errors }) {

    return (
        <Container>
            <Typography variant="h5">Devices Import Report</Typography>
            <Box sx={{ margin: "0.5em" }}>
                <Typography variant="body2">{devices.length} device(s) have been imported from '{filename}' successfully.</Typography>
                { errors.length > 0 && <Typography variant="body2">{errors.length} device(s) could not be imported.</Typography> }
            </Box>
            <ImportErrorList errors={errors} />
            { devices.length > 0 && <ImportedDevicesTable devices={devices} /> }
        </Container>
    );

}