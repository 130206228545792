import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import { getItem } from "../../APIHelper";
import { LoadingState } from "../atoms/LoadingState";

import { CircularProgress, Stack } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ActionIcon from "../atoms/ActionIcon";
import SensaraAlert from "../atoms/SensaraAlert";
import ResidentDetailsOverview from "../organisms/residents/ResidentDetailsOverview";

export default function ResidentDetails() {
    const { residentId } = useParams();
    const auth = useAuth();
    const [person, setPerson] = useState(null);
    const [personLoadingState, setPersonLoadingState] = useState(
        LoadingState.IDLE
    );
    const navigate = useNavigate();

    const getPerson = () => {
        const token = auth.user?.access_token;
        getItem({
            token: token,
            path: "/v3alpha/persons/" + residentId,
            setLoadingState: setPersonLoadingState,
            setItem: (person) => {
                setPerson(person)
            },
        });
    };

    useEffect(getPerson, [auth, residentId]);

    const idle = personLoadingState === LoadingState.IDLE;
    const loading = personLoadingState === LoadingState.LOADING;
    const showPerson = personLoadingState === LoadingState.LOADED && person;
    const noneFound = personLoadingState === LoadingState.LOADED && !person;
    const error = personLoadingState === LoadingState.ERROR;

    return (
        <Stack ml={2}>
            {(idle || loading) && <CircularProgress />}
            {showPerson && <ResidentDetailsOverview person={person} />}
            {noneFound && (
                <SensaraAlert severity="info" message="Person does not exist" />
            )}
            {error && (
                <SensaraAlert severity="error" message="Person could not be loaded." />
            )}
            <Stack spacing={2} mt={5} direction="row">
                <ActionIcon
                    action={() => navigate("/residents/overview")}
                    icon={<ArrowBackIcon />}
                    label="Back"
                />
            </Stack>
        </Stack>

    );
}