import React, { useState } from "react";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import { LoadingState } from "../atoms/LoadingState";
import SectorFilterMenu from "../molecules/SectorFilterMenu";
import TechnicalAlarmsOverviewTable from "../organisms/devices/TechnicalAlarmsOverviewTable";
import LoadingMessages from "../molecules/LoadingMessages";
import Page from "./Page";

export default function TechnicalAlarmsOverview() {

    const auth = useAuth();
    const [ selectedSector, setSelectedSector ] = useState("");
    const [ technicalAlarms, setTechnicalAlarms ] = useState([]);
    const [ loadingState, setLoadingState ] = useState(LoadingState.IDLE);

    const getTechnicalAlarmsForSector = (sector) => {
        const token = auth.user?.access_token;
        setSelectedSector(sector);
        getItems({
            token: token,
            path: "/informationserver/proapi/technicalAlarms/" + sector.id,
            setLoadingState: setLoadingState,
            setItems: (technicalAlarms) => {
                setTechnicalAlarms(technicalAlarms.sort((a1, a2) => a2.createdUtc - a1.createdUtc));
            }
        });
    };

    return (
        <Page title={"Technical Alarms" + (selectedSector ? " of " + selectedSector.name : "")}
            filters={<SectorFilterMenu onSelectSector={getTechnicalAlarmsForSector} />}>
            <LoadingMessages loadingState={loadingState} entries={technicalAlarms} messages={{
                idle: "Please select a sector from the list.",
                noEntriesFound: "Currently, no technical alarms are linked to this sector.",
                error: "The technical alarms for this sector could not be loaded."
            }} />
            { loadingState === LoadingState.LOADED && technicalAlarms.length > 0
                && <TechnicalAlarmsOverviewTable technicalAlarms={technicalAlarms} /> }
        </Page>
    );
}