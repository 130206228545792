import React from "react";

import Page from "./Page";
import IntegrationOverview from "../organisms/integrations/IntegrationOverview";

export default function Integrations() {

    return (
        <Page title="Integrations">
            <IntegrationOverview/>
        </Page>
    );
}
