import { Edit } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CircularProgress, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getItems, putItem } from "../../../APIHelper";
import { SET_FEEDBACK } from "../../../actions";
import ActionIcon from '../../atoms/ActionIcon';
import AlarmSetting from '../../atoms/AlarmSetting';
import { LoadingState } from "../../atoms/LoadingState";
import AlarmSettingDialog from '../../molecules/AlarmSettingDialog';
import GatewayTable from '../devices/GatewayTable';
import HardwareSetTable from '../devices/HardwareSetTable';

export default function ResidentDetails({ person }) {
    const auth = useAuth();
    const [hardwareset, setHardwareset] = useState([]);
    const [hardwaresetLoadingState, setHardwaresetLoadingState] = useState(LoadingState.IDLE);
    const [gateway, setGateway] = useState([]);
    const [gatewayLoadingState, setGatewayLoadingState] = useState(LoadingState.IDLE);
    const [alarmSettings, setAlarmSettings] = useState([]);
    const [settingsLoadingState, setSettingsLoadingState] = useState(LoadingState.IDLE);
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [activeAlarm, setActiveAlarm] = useState(null);

    const dispatch = useDispatch();

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };

    const handleClickOpen = (activeAlarm) => {
        activeAlarm.residentId = person.id;
        setActiveAlarm(activeAlarm);
        setOpen(true);
    };

    const putSettings = (settings) => {
        const token = auth.user?.access_token;
        putItem({
            token: token,
            path: "/v3alpha/alarmsettings/" + person.id,
            item: settings,
            onSuccess: () => {
                setFeedback("info", `Alarm settings are configured`);
                getAlarmSettings();
            },
            onFailure: (message) => setFeedback("error", "Error while updating the alarm settings: " + message)
        })
        handleClose();
    }

    const handleClose = () => {
        setActiveAlarm(null);
        getAlarmSettings();
        setOpen(false);
    };

    const address = {
        street: person.address.split(";")[0],
        number: person.address.split(";")[1],
        zipcode: person.address.split(";")[2],
        city: person.address.split(";")[3]
    }

    const getAlarmSettings = React.useCallback(async () => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/alarmsettings?residentId=" + person.id,
            setLoadingState: setSettingsLoadingState,
            setItems: (json) => {
                setAlarmSettings(json);
            }
        })

    }, [auth, person.id]);

    const getGateway = React.useCallback(async () => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/hardware/gateways/byresident/" + person.id,
            setLoadingState: setGatewayLoadingState,
            setItems: (json) => setGateway(json)
        });
    }, [auth, person.id]);

    const getHardwareset = React.useCallback(async () => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/hardware/sets?residentId=" + person.id,
            setLoadingState: setHardwaresetLoadingState,
            setItems: (json) => setHardwareset(json)
        })
    }, [auth, person.id]);

    useEffect(() => {
        getAlarmSettings();
        if (person.serviceType === "PROCARE") {
            getHardwareset();
        } else {
            getGateway();
        }
    }, [getAlarmSettings, getGateway, getHardwareset, person.serviceType]);

    const idleSettings = settingsLoadingState === LoadingState.IDLE;
    const loadingSettings = settingsLoadingState === LoadingState.LOADING;
    const showSettings = settingsLoadingState === LoadingState.LOADED && alarmSettings;

    const idleHardwareset = hardwaresetLoadingState === LoadingState.IDLE;
    const loadingHardwareset = hardwaresetLoadingState === LoadingState.LOADING;
    const showHardwareset = hardwaresetLoadingState === LoadingState.LOADED && hardwareset;

    const idleGateway = gatewayLoadingState === LoadingState.IDLE;
    const loadingGateway = gatewayLoadingState === LoadingState.LOADING;
    const showGateway = gatewayLoadingState === LoadingState.LOADED && gateway;

    return (
        <Paper elevation={0}>
            <Grid container mt={1} spacing={2} justifyContent="center">
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Resident details" />
                        <CardContent>
                            <div>
                                <TextField variant="standard" label="Name" defaultValue={person.fullName} disabled sx={{ width: "70%", m: 1 }} />
                                <TextField variant="standard" label="Client number" defaultValue={0} disabled sx={{ width: "20%", m: 1 }} />
                            </div>
                            {person.serviceType === 'PROCARE' &&
                                <div>
                                    <TextField variant="standard" label="Room name" defaultValue={address.street} disabled sx={{ width: "70%", m: 1 }} />
                                    <TextField variant="standard" label="Room number" defaultValue={address.number} disabled sx={{ width: "20%", m: 1 }} />
                                </div>
                            }
                            {person.serviceType !== 'PROCARE' &&
                                <div>
                                    <TextField variant="standard" label="Street" defaultValue={address.street} disabled sx={{ width: "70%", m: 1 }} />
                                    <TextField variant="standard" label="Number" defaultValue={address.number} disabled sx={{ width: "20%", m: 1 }} />
                                    <TextField variant="standard" label="Zipcode" defaultValue={address.zipcode} disabled sx={{ width: "35%", m: 1 }} />
                                    <TextField variant="standard" label="Zipcode" defaultValue={address.city} disabled sx={{ width: "55%", m: 1 }} />
                                </div>
                            }
                            <div>
                                <TextField variant="standard" label="Phonenumber" defaultValue={person.phoneNumbers.length !== 0 ? person.phoneNumbers[0].number : ""} disabled sx={{ width: "55%", m: 1 }} />
                                <TextField variant="standard" label="Servicetype" defaultValue={person.serviceType} disabled sx={{ width: "35%", m: 1 }} />
                            </div>
                            <TextField variant="standard" label="Sector" defaultValue={person.sector.name} disabled sx={{ width: "50%", m: 1 }} />
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => navigate(`/residents/states/${person.id}`)}>History</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    {(idleSettings || loadingSettings) && <CircularProgress />}
                    {showSettings &&
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h6'>Alarm settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormGroup>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {!!alarmSettings && alarmSettings.map((alarm) => (
                                                    <TableRow
                                                        key={alarm.alarmType}
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                    >
                                                        <AlarmSetting value={alarm} />
                                                        <TableCell>
                                                            <ActionIcon action={() => handleClickOpen(alarm)} icon={<Edit />} label="Edit" />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>

                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    }
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Alarm center</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Work in progress..</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>ECD</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Work in progress..</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>Installation</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Work in progress..</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={10}>
                    <Card>
                        <CardHeader title="Hardware" />
                        <CardContent>
                            {idleGateway && loadingGateway &&
                                <CircularProgress />
                            }
                            {showGateway &&
                                <GatewayTable gateways={[gateway]} />
                            }
                            {idleHardwareset && loadingHardwareset &&
                                <CircularProgress />
                            }
                            {showHardwareset && hardwareset.length > 0 &&
                                <HardwareSetTable hardwaresets={hardwareset} />
                            }
                            {hardwareset.length === 0 && gateway.length === 0 &&
                                <Typography>No hardware</Typography>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {activeAlarm !== null &&
                <AlarmSettingDialog
                    isOpen={open}
                    closeDialog={() => handleClose()}
                    alarm={activeAlarm}
                    putSettings={(settings) => putSettings(settings)}
                />
            }
        </Paper>
    );
}