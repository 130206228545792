import * as React from "react";
import { Alert, Stack } from "@mui/material";

export default function SensaraAlert({message, severity}) {

    return (
        <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert variant="outlined" severity={severity}>{message}</Alert>
        </Stack>
    );
}
