import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { getItems } from "../../../APIHelper";
import { LoadingState } from "../../atoms/LoadingState";
import SensaraAlert from "../../atoms/SensaraAlert";

export default function AlarmsSummary() {

  const auth = useAuth();
  const organization = useSelector((state) => state.organization);

  const [alarms, setAlarms] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

  useEffect(() => {
    const token = auth.user?.access_token;
    if (organization && organization.id) {
      getItems({
        token: token,
        path:
          "/informationserver/proapi/alarms?organizationId=" +
          organization.id,
        setLoadingState: setLoadingState,
        setItems: setAlarms,
      });
    }
  },
    [organization, auth]
  );

  return organization && organization.id ? (
    <Card>
      <CardContent>
        <Typography variant="h5">Alarms of the past 24 hours</Typography>
        {loadingState === LoadingState.LOADING && <CircularProgress />}
        {loadingState === LoadingState.LOADED && (
          <Typography>{alarms.length}</Typography>
        )}
        {loadingState === LoadingState.ERROR && (
          <SensaraAlert
            severity="error"
            message="Failed to retrieve the alarms"
          />
        )}
      </CardContent>
    </Card>
  ) : (
    <SensaraAlert severity="info" message="Please select an organization." />
  );
};
