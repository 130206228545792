import { Card, CardContent } from "@mui/material";
import React from "react";
import { getSectorName } from "../../../functions/sectorFunctions";
import Detail from "../../atoms/Detail";

export default function AlarmSubFlowView({ alarmSubFlow, sectors }) {

	return (
		<Card>
			<CardContent>
				<Detail title="Sector" description={getSectorName(alarmSubFlow.sectorId, sectors)} />
			</CardContent>
		</Card>
	);
}
