import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { retrieveSectors } from "../../functions/sectorFunctions";
import { retrieveShifts } from "../../functions/shiftFunctions";
import { LoadingState } from "../atoms/LoadingState";
import SensaraAlert from "../atoms/SensaraAlert";
import { SET_FEEDBACK } from "../../actions";
import AlarmFlowView from "../organisms/alarmflows/AlarmFlowView";
import { useAuth } from "react-oidc-context";
import Page from "./Page";
import AlarmSubFlowShiftAddition from "../organisms/alarmflows/AlarmSubFlowShiftAddition";
import AlarmSubFlowShiftsTable from "../organisms/alarmflows/AlarmSubFlowShiftsTable";
import AlarmSubFlowView from "../organisms/alarmflows/AlarmSubFlowView";
import ActionIcon from "../atoms/ActionIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack } from "@mui/system";

export default function AlarmSubFlowShiftListPanel() {
  const auth = useAuth();
  const organization = useSelector((state) => state.organization);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alarmFlow, alarmSubFlowInit] = location.state;

  const [alarmSubFlow, setAlarmSubFlow] = useState(alarmSubFlowInit);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
  const [
    alarmSubFlowOverviewUpdateTrigger,
    setAlarmSubFlowOverviewUpdateTrigger,
  ] = useState(null);

  const [sectors, setSectors] = useState([]);
  const [sectorLoadingState, setSectorLoadingState] = useState(
    LoadingState.IDLE
  );

  const getSectors = React.useCallback(async () => {
    setSectorLoadingState(LoadingState.LOADING);
    const sectorsResponse = await retrieveSectors(auth.user?.access_token, organization);
    if (!sectorsResponse.ok) {
      setSectorLoadingState(LoadingState.ERROR);
      return [];
    }
    const json = await sectorsResponse.json();
    setSectors(json.items.filter((s) => s.name !== "ARCHIVE"));
    setSectorLoadingState(LoadingState.LOADED);
  }, [auth, organization]);

  useEffect(() => {
    getSectors();
  }, [auth, organization, getSectors]);

  const [shifts, setShifts] = useState([]);
  const [shiftLoadingState, setShiftLoadingState] = useState(LoadingState.IDLE);

  const getShifts = React.useCallback(async () => {
    setShiftLoadingState(LoadingState.LOADING);
    const shiftsResponse = await retrieveShifts(auth.user?.access_token, organization);
    if (!shiftsResponse.ok) {
      setShiftLoadingState(LoadingState.ERROR);
      return [];
    }
    const json = await shiftsResponse.json();
    setShifts(json.items);
    setShiftLoadingState(LoadingState.LOADED);
  }, [auth, organization]);

  useEffect(() => {
    getShifts();
  }, [auth, organization, getShifts]);

  const getAlarmSubFlow = React.useCallback(async () => {
    const token = auth.user?.access_token;
    const alarmSubFlowResponse = await fetch(
      window.REACT_APP_API_URL + "/v3alpha/alarm-subflows/" + alarmSubFlow.id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (!alarmSubFlowResponse.ok) {
      setLoadingState(LoadingState.ERROR);
    } else {
      const json = await alarmSubFlowResponse.json();
      json.shifts.forEach((s) => delete s.orderingPriority);
      setAlarmSubFlow(json);
      setLoadingState(LoadingState.LOADED);
    }
  }, [alarmSubFlow.id, auth]);

  useEffect(() => {
    if (alarmSubFlow.id) {
      setLoadingState(LoadingState.LOADING);
      getAlarmSubFlow();
    } else {
      setLoadingState(LoadingState.IDLE);
    }
  }, [alarmSubFlowOverviewUpdateTrigger, alarmSubFlow.id, getAlarmSubFlow]);

  const updateAlarmSubFlowShifts = async (id, alarmSubFlowShifts) => {
    const token = auth.user?.access_token;
    const alarmSubFlowResponse = await fetch(
      window.REACT_APP_API_URL + "/v3alpha/alarm-subflows/" + id + "/shifts",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ shifts: alarmSubFlowShifts }),
      }
    );
    const json = await alarmSubFlowResponse.json();
    if (alarmSubFlowResponse.ok) {
      setAlarmSubFlowOverviewUpdateTrigger(Math.random());
    } else {
      dispatch({
        type: SET_FEEDBACK,
        feedback: {
          feedBackType: "error",
          feedBackMessage:
            "Error while updating the alarm subflow shifts: " + json.detail,
        },
      });
    }
  };

  return (
    <Page variant="slim" title={alarmFlow.name}>
      {sectorLoadingState === LoadingState.LOADED && (
        <AlarmSubFlowView alarmSubFlow={alarmSubFlow} sectors={sectors} />
      )}
      <AlarmFlowView alarmFlow={alarmFlow} />
      {loadingState === LoadingState.LOADED &&
        shiftLoadingState === LoadingState.LOADED && (
          <AlarmSubFlowShiftsTable
            alarmSubFlow={alarmSubFlow}
            alarmSubFlowShifts={alarmSubFlow.shifts}
            updateAlarmSubFlowShifts={updateAlarmSubFlowShifts}
            organizationShifts={shifts}
          />
        )}
      {loadingState === LoadingState.LOADED &&
        shiftLoadingState === LoadingState.LOADED && (
          <AlarmSubFlowShiftAddition
            alarmSubflow={alarmSubFlow}
            updateAlarmSubFlowShifts={updateAlarmSubFlowShifts}
            organizationShifts={shifts}
          />
        )}
      {(loadingState === LoadingState.LOADING ||
        sectorLoadingState === LoadingState.LOADING ||
        shiftLoadingState === LoadingState.LOADING) && <CircularProgress />}
      {loadingState === LoadingState.ERROR && (
        <SensaraAlert
          severity="error"
          message="The alarm subflow could not be loaded."
        />
      )}
      {sectorLoadingState === LoadingState.ERROR && (
        <SensaraAlert
          severity="error"
          message="The sectors could not be loaded."
        />
      )}
      {shiftLoadingState === LoadingState.ERROR && (
        <SensaraAlert
          severity="error"
          message="The shifts could not be loaded."
        />
      )}
      <Stack spacing={2} mt={5} direction="row">
        <ActionIcon
          action={() =>
            navigate("/alarms/flows/subflows/overview", { state: alarmFlow })
          }
          icon={<ArrowBackIcon />}
          label="Back"
        />
      </Stack>
    </Page>
  );
}
