import React from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import SensaraThemeProvider from "./components/molecules/SensaraThemeProvider";
import MainMenu from "./components/organisms/MainMenu";
import Router from "./components/organisms/Router";
import FeedBack from "./components/organisms/FeedBack";

import { useAuth } from "react-oidc-context";

export default function App() {
    const drawerWidth = 240;
    const auth = useAuth();

    if (auth.activeNavigator === "signinSilent") {
        return <div>Signing you in ...</div>;
    } else if (auth.activeNavigator === "signoutRedirect") {
        return <div>Signing you out ...</div>;
    }

    if (auth.isLoading) {
        return <div>Auth is loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... Auth error {auth.error.message}</div>;
    }

    if (!auth.isAuthenticated) {
        auth.signinRedirect();
    }

    return (
        <SensaraThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MainMenu drawerWidth={drawerWidth} />
                <Router drawerWidth={drawerWidth} />
                <FeedBack />
            </LocalizationProvider>
        </SensaraThemeProvider>
    );
}
