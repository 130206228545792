import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import ResidentState from "../../atoms/ResidentState";

export default function ResidentStateHistoryTable({ residentStateHistory }) {

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="Resident State History">
                <TableHead>
                    <TableRow>
                        <TableCell>Old State</TableCell>
                        <TableCell>New State</TableCell>
                        <TableCell>Timestamp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {residentStateHistory.map((residentStateChange) => (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <ResidentState value={residentStateChange.oldState} />
                            <ResidentState value={residentStateChange.newState} />
                            <TableCell>{residentStateChange.timestamp}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}