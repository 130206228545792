import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

export default function TechnicalAlarmsOverviewTable({technicalAlarms}) {    

    return (
        <TableContainer>
            <Table aria-label="Alarms">
                <TableHead>
                    <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>Person</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Raised</TableCell>
                        <TableCell>Expires</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {technicalAlarms.map((technicalAlarm) => (
                        <TableRow
                            key={technicalAlarm.uuid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{technicalAlarm.location}</TableCell>
                                <TableCell>{technicalAlarm.resident != null ? technicalAlarm.resident.name : ""}</TableCell>
                                <TableCell>{technicalAlarm.internalTypeName}</TableCell>
                                <TableCell>{new Date(technicalAlarm.createdUtc).toISOString()}</TableCell>
                                <TableCell>{technicalAlarm.expirationUtc > 0 ? new Date(technicalAlarm.expirationUtc).toISOString(): ""}</TableCell>
                                <TableCell>{technicalAlarm.suppressed ? "HANDLED" : "OPEN"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>      
    );
}