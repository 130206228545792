import React, { useState } from "react";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import { LoadingState } from "../atoms/LoadingState";
import LoadingMessages from "../molecules/LoadingMessages";
import SectorFilterMenu from "../molecules/SectorFilterMenu";
import ResidentsOverviewTable from "../organisms/residents/ResidentsOverviewTable";
import Page from "./Page";

export default function ResidentsOverview() {

    const auth = useAuth();

    const [selectedSector, setSelectedSector] = useState("");
    const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
    const [states, setStates] = useState([]);

    const getResidentsForSector = (sector) => {
        const token = auth.user?.access_token;
        setSelectedSector(sector);

        getItems({
            token: token,
            path: "/informationserver/proapi/residentStates/" + sector.id,
            setLoadingState: setLoadingState,
            setItems: (states) => {
                setStates(states);
            }
        })
    };

    return (
        <Page title={"Residents" + (selectedSector ? " of " + selectedSector.name : "")}
            filters={<SectorFilterMenu onSelectSector={getResidentsForSector} />}>
            <LoadingMessages loadingState={loadingState} entries={states} messages={{
                idle: "Please select a sector from the list.",
                noEntriesFound: "There are currently no residents for this sector.",
                error: "The residents for this sector could not be loaded."
            }} />
            {loadingState === LoadingState.LOADED && states.length > 0
                && <ResidentsOverviewTable states={states.sort((s1, s2) => s1.resident.name.localeCompare(s2.resident.name))} />}
        </Page>
    );
}
