import React from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import APIHelper from "../../../../APIHelper";
import { LoadingState } from "../../../atoms/LoadingState";
import BackMenu from "../../../molecules/BackMenu";
import LoadingMessages from "../../../molecules/LoadingMessages";
import Page from "../../../pages/Page";
import ShiftDetailsPanel from "./ShiftDetailsPanel";
import ShiftUserList from "./ShiftUserList";

export default function ShiftDetailsPage() {

	const { shiftId } = useParams();
	const auth = useAuth();
	const [loadingState, setLoadingState] = React.useState(LoadingState.IDLE);
	const [shift, setShift] = React.useState(null);

	const loadDetails = () => {
		const token = auth.user?.access_token;
		APIHelper.getItems({
		token: token,
		path: `/v3alpha/shifts/${shiftId}`,
		setLoadingState: setLoadingState,
		setItems: setShift
	})};

	React.useEffect(loadDetails, [shiftId, auth]);

	return (
		<Page
			title={`Shift ${shift ? shift.name : shiftId}`}
			filters={<BackMenu parentPath="/alarms/shifts/overview" parentName="all shifts" />}>
			<LoadingMessages loadingState={loadingState} entries={shift ? [1] : []} messages={{
				idle: "Waiting for the page to load the details.",
				noEntriesFound: `No shift could be found with the id '${shiftId}'.`,
				error: "No details of the shift could be loaded"
			}} />
			{loadingState === LoadingState.LOADED && shift && <ShiftDetailsPanel shift={shift} />}
			<ShiftUserList shiftId={shiftId} />
		</Page>
	);
}