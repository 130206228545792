import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function ConfirmationDialog({ title, description, action, isOpen, closeDialog }) {

    return (
        <Dialog open={isOpen} onClose={closeDialog} aria-labelledby="confirmation-dialog-title" aria-describedby="confirmation-dialog-description">
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirmation-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { closeDialog(); action(); }}>Yes</Button>
                <Button onClick={closeDialog} autoFocus>No</Button>
            </DialogActions>
        </Dialog>
    );
}
