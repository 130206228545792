import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Box, TextField } from "@mui/material";

import { useAuth } from "react-oidc-context";

import { CLEAR_ORGANIZATION, SELECT_ORGANIZATION, SET_FEEDBACK } from "../../../actions";
import { getItems } from "../../../APIHelper";
import { useNavigate } from "react-router-dom";

const getOrganization = state => state.organization;

export default function OrganizationSelector() {
    const auth = useAuth();

    const organization = useSelector(getOrganization);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ options, setOptions ] = useState([]);

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };

    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
          color: '#DADADA',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#DADADA',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#DADADA',
          },
          '&:hover fieldset': {
            borderColor: '#DADADA',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#DADADA',
          },
          '.MuiAutocomplete-clearIndicator':{
            color: '#DADADA'
          },
          '.MuiAutocomplete-popupIndicator':{
            color: '#DADADA'
          },
          '.MuiAutocomplete-popupIndicatorOpen':{
            color: '#DADADA'
          }
        },
      });

    useEffect(() => {
      const token = auth.user?.access_token;
      getItems({
        token: token,
        path: "/v3alpha/organizations",
        setItems: (organizations) => {
            setOptions(organizations);
            if (organizations.length === 1) {
                dispatch({ type: SELECT_ORGANIZATION, organization: organizations[0] });
            }
        }
    });
    }, [auth, dispatch]);

    return (
        <Box sx={{ width: 200 }}>
            {options.length > 1 ?
                <Autocomplete
                    sx={{clearIndicator:{color: "white"}}}
                    id="organizationac"
                    options={options}
                    getOptionLabel={(option) => option.description}
                    value={organization}
                    onChange={(event, value) => {
                        if (value) {
                            dispatch({type: SELECT_ORGANIZATION, organization: value});
                            setFeedback("info", "Organization " + value.description + " is selected");
                        } else {
                            dispatch({type: CLEAR_ORGANIZATION });
                            setFeedback("info", "No organization is selected");
                        }
                        navigate("/");
                    }}
                    renderInput={(params) => (
                        <CssTextField {...params} id="organization" label="Organization" variant="outlined" sx={{label: {color: "#fff"}, input: {color: "#fff"} }}/>
                    )}
                />
                : 
                <div/>
            }
        </Box>
    );
}
