import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import DevicesDetails from './DevicesDetails';
import HardwareSetOverview from './HardwareSetOverview';

export default function HardwareSetDevicesOverview({ hardwareSet }) {

  return (
    <Paper elevation={0}>
      <Grid container mt={1} spacing={2} justifyContent="center">
        <Grid item xs={6}>
          <HardwareSetOverview hardwareSet={hardwareSet} />
        </Grid>
        <Grid item xs={4}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>Degradation events</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Work in progress...</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={10}>
          <DevicesDetails set={hardwareSet} />
        </Grid>
      </Grid>
    </Paper>
  );
}