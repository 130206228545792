import React from "react";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material";

export default function SensaraThemeProvider({ children }) {

    // Sensara 'huisstijl'
    const pink = "#e5004b";
    const purple = "#421c42";
    const darkGrey = "#484a4f";
    const lightGrey = "#dadada";
    const transparent = "#00000000";
    const mainFontFamily = "Montserrat,Helvetica,sans-serif";
    const headerFonts = { fontFamily: "Dosis,sans-serif" };

    // The property names of the MUI theme can be found at https://mui.com/material-ui/customization/default-theme/
    const theme = createTheme({
        palette: {
            primary: {
                main: `${pink}`
            },
            secondary: {
                main: `${purple}`
            },
            text: {
                primary: `${darkGrey}`
            },
            error: {
                main: `${pink}`
            },
            info: {
                main: `${purple}`
            },
            background: {
                secondary: `${lightGrey}`
            }
        },
        typography: {
            fontFamily: `${mainFontFamily}`,
            h1: headerFonts,
            h2: headerFonts,
            h3: headerFonts,
            h4: headerFonts,
            h5: headerFonts,
            h6: headerFonts,
            h7: headerFonts,
            body1: headerFonts,
            button: headerFonts
        },
        components: {
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontSize: "1.1rem",
                        lineHeight: "1.1"
                    }
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            backgroundColor: `${transparent}`,
                            color: `${pink}`,
                            "&:hover": {
                                backgroundColor: `${transparent}`
                            },
                            "& .MuiListItemIcon-root": {
                                color: `${pink}`
                            }
                        }
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        fontWeight: 600
                    }
                }
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );

}