import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import { LoadingState } from "../atoms/LoadingState";
import ShiftsOverviewTable from "../organisms/alarms/shifts/ShiftsOverviewTable";
import Page from "./Page";
import LoadingMessages from "../molecules/LoadingMessages";

export default function ShiftsOverview() {

    const auth = useAuth();
    const organization = useSelector(state => state.organization);

    const [shifts, setShifts] = useState([]);
    const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
    const [deletedShiftId, setDeletedShiftId] = useState(null);

    async function getShifts() {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/shifts?organizationId=" + organization.id,
            setLoadingState: setLoadingState,
            setItems: (shifts) => {
                setShifts(shifts.sort((s1, s2) => s1.name.toLowerCase().localeCompare(s2.name.toLowerCase())));
            }
        });
    }

    const getShiftsCallBack = useCallback(getShifts, [auth, organization]);

    const loadShifts = () => {
        if (organization.id) {
            setLoadingState(LoadingState.LOADING);
            getShiftsCallBack();
        } else {
            setLoadingState(LoadingState.IDLE);
        }
    }

    useEffect(loadShifts, [getShiftsCallBack, organization, deletedShiftId]);

    return (
        <Page title={"Shifts" + (organization.id ? " of " + organization.description : "")}>
            <LoadingMessages loadingState={loadingState} entries={shifts} messages={{
                idle: "Please select an organization.",
                noEntriesFound: "Currently, no shifts are configured for this organization.",
                error: "The shifts for this organization could not be loaded."
            }} />
            {loadingState === LoadingState.LOADED && shifts.length > 0
                && <ShiftsOverviewTable shifts={shifts} setDeletedShiftId={setDeletedShiftId} />}
            {loadingState === LoadingState.LOADED && organization.id
                && <Tooltip title="New">
                    <IconButton id="new" name="New" component={RouterLink} to="/alarms/shifts/form" variant="contained">
                        <AddIcon color="primary" />
                    </IconButton>
                </Tooltip>}
        </Page>
    );
}
