import React, {useEffect, useState} from "react";

import {Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress} from "@mui/material";
import Detail from "../../atoms/Detail";
import ActionIcon from "../../atoms/ActionIcon";
import {Delete} from "@mui/icons-material";


export default function UserDetailsDialog({ user, extension, isOpen, closeDialog, createSoftPhone, deleteSoftPhone, organizationServer, hasVoip}) {

    const hasExtension = extension.username != null;
    const [isViewing, setIsViewing] = useState(false);
    const [voipText, setVoipText] = useState("View Voip Details");
    const [displaySpinner, setDisplaySpinner] = useState(false);

    useEffect(() => {
        setDisplaySpinner(false);
    },[extension]);

    const handleDeleteSoftPhone = async () => {
        setDisplaySpinner(true);
        await deleteSoftPhone();
    }
    const handleCreateExtension = async () => {
        setDisplaySpinner(true);
        await createSoftPhone();
    }

    const handleOpenCollapse = () => {
        if (isViewing) {
            setIsViewing(false)
            setVoipText("View Voip Details");
        } else {
            setIsViewing(true);
            setVoipText("Hide Voip Details")
        }
    }

    return (
        <Box>
            <Dialog
                open={isOpen}
                fullWidth={true}
                maxWidth={"md"}>
                    <span>
                    <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
                        User Details
                    </DialogTitle>
                        </span>
                {hasExtension && user && hasVoip &&
                    <DialogContent>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Detail sx={{textAlign: 'center', width: '80px'}} title="VOIP Extension"/>
                            <ActionIcon icon={<Delete/>} action={handleDeleteSoftPhone} label="Remove Extension"/>
                        </Box>
                        <Collapse
                            in={isViewing}
                            timeout="auto"
                            unmountOnExit
                        >
                            <Divider/>
                            <Detail title="URL" description={organizationServer.url}/>
                            <Detail title="Username & CallerID" description={extension.username}/>
                            <Detail title="Password" description={extension.password}/>
                        </Collapse>
                        <Button onClick={handleOpenCollapse}>{voipText}</Button>
                        <Divider/>
                    </DialogContent>
            }
            {!hasExtension && user && hasVoip &&
                    <DialogContent>
                        <Detail title="Extension"/>
                        <Button onClick={handleCreateExtension} disabled={displaySpinner}> Generate Extension</Button>
                        <Divider/>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
                {displaySpinner && <LinearProgress/>}
            </Dialog>
        </Box>
    );
}
