import React, { Suspense, useEffect, useState } from "react";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import {getItems} from "../../APIHelper";
import UserTable from "../molecules/users/UserTable";

export default function UsersOverview() {

    const auth = useAuth();
    const organization = useSelector((state) => state.organization);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const token = auth.user?.access_token;
        if (organization && organization.id) {
            getItems({
                token: token,
                path: "/v3alpha/users?organizationId=" +
                    organization.id,
                setItems: setUsers
            });
        }
    },
        [organization, auth]
    );

    const orgIsSelected = organization && organization.id;
    const noOrgSelected = !organization || !organization.id;

    return (
        <Stack spacing={2} m={2} mt={12}>
            {noOrgSelected &&
                <Typography variant="h6">Please select an organization</Typography>
            }
            {orgIsSelected &&
                <Suspense fallback={<CircularProgress />}>
                    <UserTable
                        users={users.sort((u1, u2) => u1.email.localeCompare(u2.email))}
                        organization={organization}/>
                </Suspense>
            }
        </Stack>
    );
}
