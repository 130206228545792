import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GatewayOverview from './GatewayOverview';
import DevicesDetails from './DevicesDetails';

export default function GatewayDevicesOverview({ gateway }) {

  return (
    <Paper elevation={0}>
      <Grid container mt={1} spacing={2} justifyContent="center">
        <Grid item xs={6}>
          <GatewayOverview gateway={gateway} />
        </Grid>
        <Grid item xs={4}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>Degradation events</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Work in progress..</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={10}>
          <DevicesDetails set={gateway} />
        </Grid>
      </Grid>
    </Paper>
  );
}