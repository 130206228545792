import React from "react";
import { ReactComponent as Default } from "../../assets/residentstates/default.svg";
import { ReactComponent as OutOfRoom } from "../../assets/alarms/ST_INOUT_OUT_OF_ROOM.svg";
import { ReactComponent as NotReturnedToBed } from "../../assets/alarms/ST_NOT_RETURNED_TO_BED.svg";
import { ReactComponent as LifeCircleDetection } from "../../assets/alarms/ST_WANDERING.svg";
import { ReactComponent as BathroomGuard } from "../../assets/alarms/ST_BATHROOM_ALARM.svg";
import { ReactComponent as MobilityMonitoring } from "../../assets/alarms/ST_ACTIVITY_INACTIVITY.svg";
import { ReactComponent as OutOfBed } from "../../assets/alarms/ST_INOUT_OUT_OF_BED.svg";
import { ReactComponent as OutOfChair } from "../../assets/alarms/ST_INOUT_OUT_OF_CHAIR.svg";
import { Box, SvgIcon, TableCell, Tooltip } from "@mui/material";

const ALARM_SETTINGS = {
    NotReturnedToBed: {
        icon: NotReturnedToBed,
        color: "#fa2323",
        title: "Not returned to bed",
        description: "The resident was in bed, left the bed, and did not come back within the configured period." },
    LifeCircleDetection: {
        icon: LifeCircleDetection,
        color: "#fa2323",
        title: "Wandering detection",
        description: "The resident went outside, but did not return within the configured period." },
    BathroomGuard: {
        icon: BathroomGuard,
        color: "#fa2323",
        title: "Bathroom",
        description: "The resident has been present in the bathroom for quite a while and probably needs assistance." },
    MobilityMonitoring: {
        icon: MobilityMonitoring,
        color: "#fa2323",
        title: "Suspicious inactivity",
        description: "The resident is alone in the room, but stopped triggering sensors and might have fallen." },
    OutOfBed: {
        icon: OutOfBed,
        color: "#fa2323",
        title: "Out of bed",
        description: "The resident was in bed, and then possibly got out of bed." },
    OutOfChair: {
        icon: OutOfChair,
        color: "#fa2323",
        title: "Out of chair",
        description: "The resident was in a chair, and then possibly got out of the chair." },
    OutOfRoom: {
        icon: OutOfRoom,
        color: "#fa2323",
        title: "Out of room",
        description: "The resident was inside, then went near the door, followed by a lack of movement in the room. The resident has probably left the room." },
}

export default function AlarmSetting({ value }) {

    const setting = ALARM_SETTINGS[value.alarmType] || { icon: Default, color: "#afafaf", title: value, description: value };

    return (
        <Tooltip title={ setting.description }>
            <TableCell sx={{ padding: "0.5em" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "1em" }}>
                    <SvgIcon sx={{ backgroundColor: setting.color, color: "#ffffff", padding: "0.3em", opacity: value.enabled ? 1 : .4 }} component={ setting.icon } inheritViewBox />
                    <span>{ setting.title }</span>
                </Box>
            </TableCell>
        </Tooltip>
    );

}
