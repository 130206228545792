
import React from "react";

import { CircularProgress } from "@mui/material";

import { LoadingState } from "../atoms/LoadingState";
import SensaraAlert from "../atoms/SensaraAlert";

export default function LoadingMessages({ loadingState, entries, messages }) {

    if (loadingState === LoadingState.IDLE) {
        return <SensaraAlert severity="info" message={messages.idle} />;
    } else if (loadingState === LoadingState.LOADING) {
        return <CircularProgress />
    } else if (loadingState === LoadingState.LOADED && entries.length === 0) {
        return <SensaraAlert severity="info" message={messages.noEntriesFound} />
    } else if (loadingState === LoadingState.ERROR) {
        return <SensaraAlert severity="error" message={messages.error} />
    } else {
        return <div />
    }

}
