import React from "react";

import { Grid } from "@mui/material";

import AlarmsSummary from "../organisms/alarms/AlarmsSummary";
import TechnicalAlarmsSummary from "../organisms/technicalalarms/TechnicalAlarmsSummary";

export default function Dashboard() {

    return (
        <Grid container mt={1} spacing={2} justifyContent="center">
            <Grid item xs={3}><AlarmsSummary /></Grid>
            <Grid item xs={3}><TechnicalAlarmsSummary /></Grid>
        </Grid>
    )
};
