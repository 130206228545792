import React from "react";
import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ResidentState from "../../atoms/ResidentState";

export default function ResidentsOverviewTable({states}) {    

    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table aria-label="Residents">
                <TableHead>
                    <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {states.map((state) => (
                        <TableRow
                            key={state.uuid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{state.resident.housingUnit.bedroomName}</TableCell>
                                <TableCell><Link href="#" onClick={() => navigate(`/residents/details/${state.resident.uuid}`)}>{state.resident.name}</Link></TableCell>
                                <ResidentState value={state.internalStateName} />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>   
    );
}