import React, {useState} from "react";

import {Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import { getDeviceImage } from "../../../functions/DeviceImage";
import Detail from "../../atoms/Detail";
import BatteryStateIcon from "../../atoms/BatteryStateIcon";
import SignalStrengthIcon from "../../atoms/SignalStrengthIcon";


export default function DeviceDetailsDialog({ device, extension, isOpen, closeDialog, organizationServer }) {

    const SENSOR_TYPES = {
        MV: { label: "Motion sensor" },
        BS: { label: "Bed sensor" },
        CS: { label: "Chair sensor" },
        DR: { label: "Open/close sensor" },
        AL: { label: "Alarm button" },
        PA: { label: "Personal Alarm" },
        UNKNOWN: { label: "Unknown device type" }
    };

    const LOCATIONS = {
        ALARM_BUTTON: { label: "Alarm button" },
        BED: { label: "Bed" },
        CHAIR: { label: "Chair" },
        EXTERNAL_DOOR: { label: "External Door" },
        HALL: { label: "Hallway" },
        HOBBY_ROOM: { label: "Hobby room" },
        KITCHEN: { label: "Kitchen" },
        LIVING_ROOM: { label: "Living room" },
        PULL_CORD: { label: "Pull cord" },
        TOILET: { label: "Toilet" },
        UNKNOWN: { label: "Unknown location" },
    };



    const date = new Date(device.lastHeartBeat);
    const deviceLoaded = isOpen && device;
    const deviceNotAkuvox = device.realm !== "AKUVOX" && deviceLoaded;
    const deviceAkuvox = (device.realm === "AKUVOX") && deviceLoaded;
    const [isViewing, setIsViewing] = useState(false);
    const [voipText, setVoipText] = useState("View Voip Details");

    const handleOpenCollapse = () => {
        if (isViewing){
            setIsViewing(false)
            setVoipText("View Voip Details");
        }
        else {
            setIsViewing(true);
            setVoipText("Hide Voip Details")
        }
    }

    return (
        <Box>
            {deviceNotAkuvox &&
                <Dialog
                    open={isOpen}
                    fullWidth={true}
                    maxWidth={"md"} >
                    <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                        {device.label}
                    </DialogTitle>
                    <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={getDeviceImage(device)} height={150} alt={device.type !== undefined ? SENSOR_TYPES[device.type].label : "Unknown sensor type"} />
                    </DialogContent>
                    <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                        {device.deviceId}
                    </DialogContent>
                    <DialogContent>
                        <Divider />
                    </DialogContent>
                    <DialogContent>
                        <Detail title="Type" description={device.type !== undefined ? SENSOR_TYPES[device.type].label : "Unknown sensor type"} />
                        <Detail title="Location" description={LOCATIONS[device.location].label} />
                        <Detail title="Realm" description={device.realm} />
                        <Detail title="Status" description={device.status} />
                        <Divider />
                        <Detail title="Last event time" description={date.toLocaleString()} />
                        {device.lastValue !== null && <Detail title="Last event value" description={device.lastValue.toString()} />}
                        <Divider />
                        {device.batteryStatus !== null && <Detail title="Battery level" description={<BatteryStateIcon device={device} />} />}
                        {device.batteryVoltage > 0 && <Detail title="Battery voltage" description={(device.batteryVoltage / 10).toFixed(1) + " V"} />}
                        <Divider />
                        <Detail title="Connection" description={device.connectionStatus} />
                        <Detail title="Signal strength" description={<SignalStrengthIcon rssi={device.rssi} />} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
            {deviceAkuvox &&
                <Dialog
                    open={isOpen}
                    fullWidth={true}
                    maxWidth={"md"} >
                    <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                        {device.label}
                    </DialogTitle>
                    <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={getDeviceImage(device)} height={150} alt={device.type !== undefined ? SENSOR_TYPES[device.type].label : "Unknown sensor type"} />
                    </DialogContent>
                    <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                        {device.deviceId}
                    </DialogContent>
                    <DialogContent>
                        <Detail title="Type" description="Akuvox VOS" />
                        <Detail title="Realm" description={device.realm} />
                        <Detail title="Status" description={device.status} />
                        <Divider />
                        <Collapse
                        in={isViewing}
                        timeout="auto"
                        unmountOnExit
                        >
                            <Detail title="URL" description={organizationServer.url} />
                            <Detail title="Username & CallerID" description={extension.username} />
                            <Detail title="Password" description={extension.password} />
                        </Collapse>
                        <Button onClick={handleOpenCollapse}>{voipText}</Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
        </Box>
    );
}
