import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Alert, Snackbar } from "@mui/material";
import { CLEAR_FEEDBACK } from "../../actions";

export default function FeedBack() {
  const feedback = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    dispatch({ type: CLEAR_FEEDBACK });
  };

  return (
    <Snackbar
      open={feedback.feedBackMessage}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={feedback.feedBackType}>
        {feedback.feedBackMessage}
      </Alert>
    </Snackbar>
  );
}
