import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { createItem, deleteItem, updateItem } from "../../APIHelper";
import { SET_FEEDBACK } from "../../actions";
import ShiftForm from "../organisms/alarms/shifts/ShiftForm";
import Page from "./Page";

export default function ShiftFormPanel() {

    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const initShift = location.state;

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };

    const createShift = (newShift) => {
        const token = auth.user?.access_token;
        createItem({
            token: token,
            path: "/v3alpha/shifts",
            item: newShift,
            onSuccess: () => {
                setFeedback("info", `Shift ${newShift.name} has been created`);
                navigate("/alarms/shifts/overview");
            },
            onFailure: (message) => setFeedback("error", "Error while creating the shift: " + message)
        })
    };

    const updateShift = (id, shiftUpdated) => {
        const token = auth.user?.access_token;
        updateItem({
            token: token,
            path: "/v3alpha/shifts/" + id,
            item: shiftUpdated,
            onSuccess: () => {
                setFeedback("info", `Shift ${shiftUpdated.name} has been updated`);
                navigate("/alarms/shifts/overview");
            },
            onFailure: (message) => setFeedback("error", "Error while updating the shift: " + message)
        })
    };

    const deleteShift = (id, name) => {
        const token = auth.user?.access_token;
        deleteItem({
            token: token,
            path: "/v3alpha/shifts/" + id,
            onSuccess: () => {
                setFeedback("info", `Shift ${name} has been deleted`);
                navigate("/alarms/shifts/overview");
            },
            onFailure: (message) => setFeedback("error", "Error while deleting the shift: " + message)
        })
    };

    return (
        <Page variant="slim" title={initShift && initShift.id ? "Edit Shift" : "Create Shift"}>
            <ShiftForm
                initShift={initShift}
                createShift={createShift}
                updateShift={updateShift}
                deleteShift={deleteShift} />
        </Page>
    );
}
