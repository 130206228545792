import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";

import APIHelper from "../../../../APIHelper";
import { useAuth } from "react-oidc-context";
import { LoadingState } from "../../../atoms/LoadingState";
import LoadingMessages from "../../../molecules/LoadingMessages";
import TableHeader from "../../../atoms/TableHeader";

import { SET_FEEDBACK } from "../../../../actions";
import ActionIcon from "../../../atoms/ActionIcon";
import ConfirmationDialog from "../../../molecules/ConfirmationDialog";
import ItemAdder from "../../../molecules/ItemAdder";

const getOrganization = state => state.organization;

export default function ShiftUserList({ shiftId }) {

	const auth = useAuth();
	const dispatch = useDispatch();
	const organization = useSelector(getOrganization);
	const [loadingState, setLoadingState] = React.useState(LoadingState.IDLE);
	const [allUsers, setAllUsers] = React.useState([]);
	const [shiftUsers, setShiftUsers] = React.useState([]);
	const [userToRemove, setUserToRemove] = React.useState(null);
	const availableUsers = allUsers.filter(allUser => !shiftUsers.find(shiftUser => shiftUser.id === allUser.id));

	const showFeedback = (severity, message) => dispatch({
		type: SET_FEEDBACK,
		feedback: { feedBackType: severity, feedBackMessage: message }
	});

	const loadUsers = () => {
		const token = auth.user?.access_token;
		APIHelper.getItems({
			token: token,
			path: `/v3alpha/organizations/${organization.id}/users`,
			setItems: setAllUsers
		});
		APIHelper.getItems({
			token: token,
			path: `/v3alpha/shifts/${shiftId}/users`,
			setLoadingState: setLoadingState,
			setItems: setShiftUsers
		});
	};

	const getUsername = (user) => {
		if (user.name) {
			return user.name;
		} else {
			const foundUser = allUsers.find(allUser => allUser.id === user.id);
			return foundUser ? foundUser.name : user.id;
		}
	};

	const addUser = (user) => {
		const token = auth.user?.access_token;
		APIHelper.createItem({
			token: token,
			path: `/v3alpha/shifts/${shiftId}/users`,
			item: user,
			onSuccess: () => setShiftUsers([...shiftUsers, user]),
			onFailure: (error) => showFeedback("error", `The user could not be added to this shift: ${error}`)
		});
	};

	const removeUser = (user) => {
		const token = auth.user?.access_token;
		APIHelper.deleteItem({
			token: token,
			path: `/v3alpha/shifts/${shiftId}/users/${user.id}`,
			onSuccess: () => {
				setShiftUsers(shiftUsers.filter(shiftUser => shiftUser.id !== user.id));
				showFeedback("info", `User ${user.id} has been removed from the shift.`);
			},
			onError: (error) => showFeedback("error", `The user could not be removed from this shift: ${error}`)
		});
		setUserToRemove(null);
	};

	React.useEffect(loadUsers, [shiftId, auth, organization.id]);

	return (
		<Stack spacing={1}>
			<LoadingMessages loadingState={loadingState} entries={shiftUsers} messages={{
				idle: "Waiting for the page to load the users.",
				noEntriesFound: `No users have been linked to this shift yet.`,
				error: "Could not load the users."
			}} />
			{loadingState === LoadingState.LOADED && shiftUsers.length > 0
				&& <TableContainer>
					<Table aria-label="Shift users">
						<TableHeader columns={["User", "Actions"]} />
						<TableBody>
							{shiftUsers.map((user) => (
								<TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell>{getUsername(user)}</TableCell>
									<TableCell>
										<ActionIcon action={() => setUserToRemove(user)} icon={<Delete />} label="Delete" />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<ConfirmationDialog
						title="Remove user?"
						description={`Are you sure you want to remove user ${userToRemove ? getUsername(userToRemove) : ""} from the shift?`}
						action={() => removeUser(userToRemove)}
						isOpen={userToRemove !== null}
						closeDialog={() => setUserToRemove(null)} />
				</TableContainer>}
			<ItemAdder title="Add a user" items={availableUsers} addItem={addUser} />
		</Stack>
	);

}