import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TableCell, TextField } from "@mui/material";
import AlarmSetting from "../atoms/AlarmSetting";

import { Delete, MoreTime } from "@mui/icons-material";
import ActionIcon from "../atoms/ActionIcon";

export default function AlarmSettingDialog({ alarm, isOpen, closeDialog, putSettings }) {

	const [enabled, setEnabled] = useState(alarm.enabled);
	const [entireDay, setEntireDay] = useState(alarm.activeEntireDay);
	const [alarmSettings, setAlarmSettings] = useState(alarm)
	const { register, handleSubmit, control } = useForm({ defaultValues: alarm });
	const { fields, append, remove } = useFieldArray({ name: "activePeriods", control });

	const delays = [
		{ value: 0, label: "No delay" },
		{ value: 60, label: "1 minute" },
		{ value: 120, label: "2 minutes" },
		{ value: 300, label: "5 minutes" },
		{ value: 600, label: "10 minutes" },
		{ value: 1200, label: "20 minutes" },
		{ value: 1800, label: "30 minutes" },
		{ value: 3600, label: "1 hour" },
		{ value: 7200, label: "2 hours" },
		{ value: 10800, label: "3 hours" },
		{ value: 14400, label: "4 hours" }
	];

	const handleEnabledSwitch = (e) => {
		setEnabled(e.target.checked);
		alarmSettings.enabled = e.target.checked
		setAlarmSettings(alarmSettings);
	};

	const handleEntireDaySwitch = (e) => {
		setEntireDay(e.target.checked);
		alarmSettings.activeEntireDay = e.target.checked
		setAlarmSettings(alarmSettings);
	};

	return (
		<form onSubmit={handleSubmit((settings) => putSettings(settings))} id="settingsForm">
			<Dialog open={isOpen}>
				<DialogTitle sx={{ display: "flex", alignItems: "center" }}>
					<AlarmSetting value={alarmSettings} />
					<TableCell sx={{ padding: "0.5em" }}><FormControlLabel control={<Switch checked={enabled} onChange={handleEnabledSwitch} />} {...register("enabled")} label="Enabled" /></TableCell>
					<TextField defaultValue={alarm.residentId} variant="standard" sx={{ width: "35%", mr: 1, display: 'none' }} {...register("residentId")} />
					<TextField defaultValue={alarm.alarmType} variant="standard" sx={{ width: "35%", mr: 1, display: 'none' }} {...register("alarmType")} />
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
							<FormControlLabel control={<Switch checked={entireDay} onChange={handleEntireDaySwitch} />} {...register("activeEntireDay")} label="Entire day" />
						</Box>
						{!entireDay && fields.map((period, index) => (
							<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
								<TextField type="text" defaultValue={period.startTime} variant="standard" sx={{ width: "35%", mr: 1 }} {...register(`activePeriods.${index}.startTime`, { maxLength: 5, pattern: /([0-1]?[0-9]|2[0-3]):[0-5][0-9]/ })} />
								<TextField type="text" defaultValue={period.endTime} variant="standard" sx={{ width: "35%", mr: 1 }} {...register(`activePeriods.${index}.endTime`, { maxLength: 5, pattern: /([0-1]?[0-9]|2[0-3]):[0-5][0-9]/ })} />
								<ActionIcon icon={<Delete />} label="Delete" action={() => remove(index)} />
							</Box>
						))}
						{!entireDay &&
							<Box sx={{ width: "100%", mt: 1 }}>
								<Button startIcon={<MoreTime />} onClick={() => append({ startTime: "23:00", endTime: "07:00" })} sx={{ width: "35%", mr: 1 }}>Timeslot</Button>
							</Box>
						}
						<FormControl variant="standard" sx={{ width: "75%", mt: 1 }}>
							<InputLabel>Delay</InputLabel>
							<Select defaultValue={alarm.delaySeconds} {...register("delaySeconds")}>
								{delays.map((delay) => (
									<MenuItem value={delay.value}>{delay.label}</MenuItem>
								))}
							</Select>
						</FormControl>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button type="submit" form="settingsForm">Save</Button>
					<Button onClick={closeDialog}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
}