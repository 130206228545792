import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { createItem, deleteItem, updateItem } from "../../APIHelper";
import { SET_FEEDBACK } from "../../actions";
import AlarmFlowForm from "../organisms/alarmflows/AlarmFlowForm";
import Page from "./Page";

export default function AlarmFlowFormPanel() {

    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const initAlarmFlow = location.state;

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };

    const createAlarmFlow = (newAlarmFlow) => {
        const token = auth.user?.access_token;
        createItem({
            token: token,
            path: "/v3alpha/alarm-flows",
            item: newAlarmFlow,
            onSuccess: () => {
                setFeedback("info", `Alarm flow ${newAlarmFlow.name} has been created`);
                navigate("/alarms/flows/overview");
            },
            onFailure: (message) => setFeedback("error", "Error while creating the alarm flow: " + message)
        })
    };

    const updateAlarmFlow = (id, alarmFlowUpdated) => {
        const token = auth.user?.access_token;
        updateItem({
            token: token,
            path: "/v3alpha/alarm-flows/" + id,
            item: alarmFlowUpdated,
            onSuccess: () => {
                setFeedback("info", `Alarm flow ${alarmFlowUpdated.name} has been updated`);
                navigate("/alarms/flows/overview");
            },
            onFailure: (message) => setFeedback("error", "Error while updating the alarm flow: " + message)
        })
    };

    const deleteAlarmFlow = (id, name) => {
        const token = auth.user?.access_token;
        deleteItem({
            token: token,
            path: "/v3alpha/alarm-flows/" + id,
            onSuccess: () => {
                setFeedback("info", `Alarm flow ${name} has been deleted`);
                navigate("/alarms/flows/overview");
            },
            onFailure: (message) => setFeedback("error", "Error while deleting the alarm flow: " + message)
        })
    };

    return (
        <Page variant="slim" title={initAlarmFlow && initAlarmFlow.id ? "Edit Alarm Flow" : "Create Alarm Flow"}>
            <AlarmFlowForm
                initAlarmFlow={initAlarmFlow}
                createAlarmFlow={createAlarmFlow}
                updateAlarmFlow={updateAlarmFlow}
                deleteAlarmFlow={deleteAlarmFlow} />
        </Page>
    );
}
