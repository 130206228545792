import {format, parse} from "date-fns";

const temporalFormat = "HH:mm";

export const formatTime = d => {
    try {
        return format(d, temporalFormat);
    } catch (error) {
        return '';
    }
}

export const parseTime = s => {
    try {
        return parse(s, temporalFormat, new Date());
    } catch (error) {
        return null;
    }
}

export const reformatTime = s => {
    return formatTime(parseTime(s));
}

export const dayMap = { MONDAY: 'Monday', TUESDAY: 'Tuesday', WEDNESDAY: 'Wednesday', THURSDAY: 'Thursday', FRIDAY: 'Friday', SATURDAY: 'Saturday', SUNDAY: 'Sunday' }
