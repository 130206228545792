import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Autocomplete, Button, TextField } from "@mui/material";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../APIHelper";
import { LoadingState } from "../atoms/LoadingState";
import PeriodSelector, { getStartOfPeriod } from "../atoms/PeriodSelector";
import FilterMenu from "../molecules/FilterMenu";
import LoadingMessages from "../molecules/LoadingMessages";
import ResidentStateHistoryTable from "../organisms/residentstates/ResidentStateHistoryTable";
import Page from "./Page";

export default function ResidentStateHistories() {

    const auth = useAuth();
    const organization = useSelector(state => state.organization);
    const [period, setPeriod] = useState("2m");
    const [resident, setResident] = useState({ id: "", fullName: "" });
    const [residentStateHistories, setResidentStateHistories] = useState([]);
    const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
    const [options, setOptions] = useState([]);
    const previousController = useRef();

    let before = new Date();

    async function getResidents(searchTerm) {
        const token = auth.user?.access_token;
        let controller = new AbortController();
        previousController.current = controller;

        getItems({
            token: token,
            path: "/v3alpha/persons?q=" + searchTerm,
            signal: controller.signal,
            setItems: (residents) => {
                setOptions(residents.filter(resident => !organization.id || resident.organization.id === organization.id));
            }
        });
    };

    const getResidentStateHistory = () => {
        const token = auth.user?.access_token;
        let after = getStartOfPeriod(before, period);
        getItems({
            token: token,
            path: "/v3alpha/residents/states/history?residentId=" + resident.id
                + "&createdAfter=" + after.toISOString()
                + "&createdBefore=" + before.toISOString(),
            setLoadingState: setLoadingState,
            setItems: (json) => setResidentStateHistories(json.history)
        });
    };

    const filters = (
        <FilterMenu>
            <Autocomplete
                id="residentac"
                options={options}
                getOptionLabel={(option) => option.fullName}
                value={resident}
                onInputChange={(event, value, reason) => value ? getResidents(value) : setOptions([])}
                onChange={(event, value) => value ? setResident(value) : setResident({ id: "", fullName: "" })}
                renderInput={(params) => (
                    <TextField {...params} id="resident" label="Resident" variant="outlined" />
                )}
            />
            <PeriodSelector period={period} setPeriod={setPeriod} />
            <Button variant="text" onClick={getResidentStateHistory}>Search</Button>
        </FilterMenu>
    );

    return (
        <Page title="Resident State History" filters={filters}>
            <LoadingMessages loadingState={loadingState} entries={residentStateHistories} messages={{
                idle: "Please specify a resident, select a period and click 'Search'.",
                noEntriesFound: "Currently, no resident states have been recorded for this resident.",
                error: "The state history for this resident could not be loaded."
            }} />
            {loadingState === LoadingState.LOADED && residentStateHistories.length > 0
                && <ResidentStateHistoryTable residentStateHistory={residentStateHistories} />}
        </Page>
    );
}

