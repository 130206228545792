import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { getItems } from "../../../../APIHelper";
import { LoadingState } from "../../../atoms/LoadingState";
import PeriodSelector, { getStartOfPeriod } from "../../../atoms/PeriodSelector";
import FilterMenu from "../../../molecules/FilterMenu";
import LoadingMessages from "../../../molecules/LoadingMessages";
import Page from "../../../pages/Page";
import ResidentStateHistoryTable from "../../residentstates/ResidentStateHistoryTable";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ActionIcon from "../../../atoms/ActionIcon";

export default function ResidentStateHistoryOverview() {
    const { residentId } = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const [period, setPeriod] = useState("1w");
    const [residentStateHistories, setResidentStateHistories] = useState([]);
    const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

    const getResidentStateHistory = React.useCallback(() => {
        const token = auth.user?.access_token;
        let before = new Date();
        let after = getStartOfPeriod(before, period);
        getItems({
            token: token,
            path: "/v3alpha/residents/states/history?residentId=" + residentId
                + "&createdAfter=" + after.toISOString()
                + "&createdBefore=" + before.toISOString(),
            setLoadingState: setLoadingState,
            setItems: (json) => setResidentStateHistories(json.history.ResidentState)
        });
    }, [auth, period, residentId]);

    useEffect(() => {
        getResidentStateHistory();
    }, [getResidentStateHistory]);

    const filters = (
        <FilterMenu>
            <PeriodSelector period={period} setPeriod={setPeriod} />
            <Button variant="text" onClick={getResidentStateHistory}>Search</Button>
            <Stack spacing={2} mt={5} direction="row">
                <ActionIcon
                    action={() => navigate("/residents/details/" + residentId)}
                    icon={<ArrowBackIcon />}
                    label="Back"
                />
            </Stack>
        </FilterMenu>
    );

    return (
        <Page title="Resident State History" filters={filters}>
            <LoadingMessages loadingState={loadingState} entries={residentStateHistories} messages={{
                idle: "Please specify a resident, select a period and click 'Search'.",
                noEntriesFound: "Currently, no resident states have been recorded for this resident.",
                error: "The state history for this resident could not be loaded."
            }} />
            {loadingState === LoadingState.LOADED && residentStateHistories.length > 0
                && <ResidentStateHistoryTable residentStateHistory={residentStateHistories} />}
        </Page>
    );
}

