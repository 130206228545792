import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useAuth} from "react-oidc-context";
import AddSipIntegrationDialog from "../../molecules/integrations/AddSipIntegrationDialog";
import {getAllIntegrations} from "../../../functions/IntegrationDetails";
import IntegrationCard from "../../molecules/integrations/IntegrationCard";
import {Box} from "@mui/material";
import ConfirmationDialog from "../../molecules/ConfirmationDialog";
import {createPlainTextItem, deleteItem, getItems} from "../../../APIHelper";
import {LoadingState} from "../../atoms/LoadingState";
import {SET_FEEDBACK} from "../../../actions";

export default function IntegrationOverview() {

    const initOrganization = useSelector(state => state.organization);

    const auth = useAuth();
    const dispatch = useDispatch();
    const integrations = getAllIntegrations();
    const [open, setOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [integrationToDeactivate, setintegrationToDeactivate] = useState({});
    const [activated, setActivated] = useState();
    const [pbxServers, setPbxServers] = useState([]);
    const [orgServer, setOrgServer] = useState({});
    // eslint-disable-next-line
    const [pbxLoadingState, setPbxLoadingState] = useState(LoadingState.IDLE);

    useEffect(() => {
        const token = auth.user?.access_token;
        getItems({
            token: token,
            path: "/v3alpha/voip-servers",
            setLoadingState: setPbxLoadingState,
            setItems: setPbxServers,
        });
        getItems({
            token: token,
            path: "/v3alpha/organizations/" + initOrganization.id + "/voip-integrations",
            setLoadingState: setPbxLoadingState,
            setItems: setOrgServer,
        });
    }, [auth, initOrganization]);

    useEffect(() => {
       if (orgServer.voipServerId != null){
           setActivated(true);
       }
       else{
           setActivated(false)
       }
    }, [orgServer]);

    const getPbxServers = () => {
        const token = auth.user?.access_token;
            getItems({
                token: token,
                path: "/v3alpha/voip-servers",
                setLoadingState: setPbxLoadingState,
                setItems: setPbxServers,
            });
        }

    const setFeedback = (severity, message) => {
        dispatch({
            type: SET_FEEDBACK,
            feedback: { feedBackType: severity, feedBackMessage: message }
        });
    };
const postIntegration = (sipId) => {
    const token = auth.user?.access_token;

    createPlainTextItem({
        token: token,
        path: "/v3alpha/organizations/" + initOrganization.id + "/voip-integrations",
        item: sipId.uuid,
        onSuccess: () => {
            setFeedback("info", `Sip integration activated`);
            setActivated(true);
            setOpen(false);
        },
        onFailure: (message) => setFeedback("error", "Error while activating sip integration: " + message)
    });
}

    const unlinkIntegration= () =>{
        const token = auth.user?.access_token;
        deleteItem({
            token: token,
            path: "/v3alpha/organizations/" + initOrganization.id + "/voip-integrations/" + integrationToDeactivate.voipServerId,
            onSuccess: () => {
                setFeedback("info", "SipServer " + integrationToDeactivate.uuid + " is unlinked");
                getPbxServers();
                setActivated(false);
            },
            onFailure: (error) => {
                setFeedback("error", "Error while unlinking integration: " + error);
            }
        });
    }
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDeactivation = (integration) => {
        setintegrationToDeactivate(integration);
        setConfirmation(true);
    };

    const handleConfirmationClose = () =>{
        setConfirmation(false);
        setintegrationToDeactivate({});
    };

    return (
        <Box sx={{display:"flex", p:"8px"}}>
            {
                integrations.map((integration) => (
                    <IntegrationCard
                        integration={integration}
                        handleClickOpen={handleClickOpen}
                        handleDeactivate={() => handleDeactivation(orgServer)}
                        activation={activated}
                    />
                ))
            }
            <AddSipIntegrationDialog
                isOpen={open}
                closeDialog={handleClose}
                handleActivation={(server) => postIntegration(server)}
                pbxServers={pbxServers}
            />
            <ConfirmationDialog
                title="Deactivate Integration?"
                description={"Deactivate the integration"}
                action={unlinkIntegration}
                isOpen={confirmation}
                closeDialog={handleConfirmationClose} />
        </Box>
    );
}
