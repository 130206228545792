import React from "react";
import { Tooltip, Typography } from "@mui/material";

export default function Version() {

    return (
        <Tooltip title="This application is in rapid development which means that features might be added, changed or removed at any time without prior notice.">
            <Typography sx={{ padding: "15px" }}>Rapid development</Typography>
        </Tooltip>
    );

}